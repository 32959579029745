import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { classNames } from '../../../utils';
import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import styles from './textArea.module.scss';

interface TextAreaProps extends FormFieldProps {
  maxLength?: number;
  rows?: number;
}

export const TextArea: FC<TextAreaProps> = ({ name, title, subtitle, description, placeholder, helperText, maxLength, rows, disabled = false }) => {
  const { clearErrors } = useFormContext();

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <Controller
        name={name}
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => (
          <textarea
            name={name}
            defaultValue={value}
            className={classNames(styles.textArea, error ? styles.error : '')}
            placeholder={placeholder}
            maxLength={maxLength}
            rows={rows}
            disabled={isSubmitting || disabled}
            onChange={(event) => {
              onChange(event);
              clearErrors(name);
            }}
            data-cy={`text-area-${name}`}
          />
        )}
      />
    </FieldContainer>
  );
};
