import { createContext, useContext } from 'react';

type SessionManagerContextProps = {
  sessionLoanUuid: string | null;
  setSessionLoanUuid: React.Dispatch<React.SetStateAction<string | null>> | (() => {});
};

export const SessionManagerContext = createContext<SessionManagerContextProps>({
  sessionLoanUuid: null,
  setSessionLoanUuid: () => {},
});

SessionManagerContext.displayName = 'SessionManagerContext';

export const useSessionManager = () => useContext(SessionManagerContext);
