import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const MAX_RETRIES = 3;

const RETRY_DELAY = 5000;

interface RetryOptions<ResponseData> {
  shouldRetry?: (responseData: ResponseData | undefined) => boolean;
  maxRetries?: number;
  retryDelay?: number;
  retryOnError?: boolean;
}

export const axiosRetry = async <ResponseData>(
  requestConfig: AxiosRequestConfig,
  { shouldRetry, maxRetries = MAX_RETRIES, retryDelay = RETRY_DELAY, retryOnError = false }: RetryOptions<ResponseData>
): Promise<AxiosResponse<ResponseData> | undefined> => {
  let retries = 0;

  const requestHandler = async (): Promise<AxiosResponse<ResponseData>> => await axios.request<ResponseData>(requestConfig).then(responseHandler);

  const responseHandler = async (response: AxiosResponse<ResponseData>): Promise<AxiosResponse<ResponseData>> => {
    if (retries < maxRetries && shouldRetry != null && shouldRetry(response.data)) {
      retries++;
      return await retryHandler();
    }

    return response;
  };

  const errorHandler = async (error: AxiosError<ResponseData>): Promise<AxiosResponse<ResponseData>> => {
    if (retryOnError && retries < maxRetries) {
      retries++;
      return await retryHandler();
    }

    console.error('axiosRetry', 'Failed with error', error);

    throw error;
  };

  const retryHandler = async (): Promise<AxiosResponse<ResponseData>> =>
    await new Promise((resolve) => setTimeout(() => resolve(requestHandler().catch(errorHandler)), retryDelay));

  return await requestHandler().catch(errorHandler);
};
