import React, { FC, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { MicroFrontend } from 'components/MicroFrontend/MicroFrontend';

import { MobileVerification } from '../components';
import ErrorFallback from '../components/ErrorFallback';
import { matchTld } from '../utils';
import Login from '../views/Login';
import { Verification } from '../views/Verification/Verification';

import Error404 from './error404';
import { HomePageRedirect } from './HomePageRedirect';
import SessionManager from './session-manager';

const Portal = lazy(() => import('../views/Portal/Portal'));
const ClearScoreReferral = lazy(() => import('../views/Referral/ClearScore/ClearScoreReferral'));
const ReferralExperience = lazy(() => import('../views/Referral/ReferralExperience'));
const AlreadyAssessed = lazy(() => import('../views/Unsuccessful/AlreadyAssessed'));
const Admin = lazy(() => import('../views/Admin'));
const PartnerPage = lazy(() => import('../views/Partner/partnerPage'));
const IFrame = lazy(() => import('../views/IFrame'));
const NoMatch = lazy(() => import('../views/NoMatch'));
const Success = lazy(() => import('../views/Success'));
const InstantQuote = lazy(() => import('../views/InstantQuote/InstantQuote'));
const MissingDetails = lazy(() => import('../views/MissingDetails'));

const quoteJourneyHost = matchTld(process.env.REACT_APP_QUOTE_JOURNEY_MFE_HOST ?? '');
const quoteJourneyName = 'QuoteJourney';

const AppRoutes: FC = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <SessionManager>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={{ pathname: '/welcome', search: window.location.search }} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/portal/*" element={<Portal />} />
          <Route path="/start/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/welcome/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/buy/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/refinance/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/pl/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/partial-personal-loan/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/partial/*" element={<MicroFrontend host={quoteJourneyHost} name={quoteJourneyName} />} />
          <Route path="/quote/:uuid" element={<InstantQuote />} />
          <Route path="/q/:uuid?" element={<InstantQuote />} />
          <Route path="/unsuccessful/:uuid?" element={<ClearScoreReferral />} />
          <Route path="/referral/:uuid" element={<ReferralExperience />} />
          <Route path="/nomatch" element={<NoMatch />} />
          <Route path="/success" element={<Success />} />
          <Route path="/alreadyassessed" element={<AlreadyAssessed />} />
          <Route path="/partner/:partnerName?" element={<PartnerPage />} />
          <Route path="/frame" element={<IFrame />} />
          <Route path="/ad/:JWT" element={<Admin />} />
          <Route path="/missing-details/*" element={<MissingDetails />} />
          <Route path="/verification/*" element={<Verification />}>
            <Route path="mobile" element={<MobileVerification />} />
          </Route>
          <Route path="*" element={<HomePageRedirect />} />
          <Route element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </SessionManager>
  </ErrorBoundary>
);

export default AppRoutes;
