import { createBreakpoints } from '@mui/system';

import { ThemeComponents } from './types';

const breakpoints = createBreakpoints({});

export const MuiTypography: ThemeComponents['MuiTypography'] = {
  styleOverrides: {
    h1: {
      fontSize: '64pt',
      lineHeight: '72pt',
      fontWeight: 400,
    },
    h2: {
      fontSize: '56pt',
      lineHeight: '62pt',
      fontWeight: 400,
      fontFamily: 'ivyjournal,sans-serif',

      '@media (max-width: 780px)': {
        fontSize: '48pt',
        lineHeight: '52pt',
        textAlign: 'center',
      },
      '@media (max-width: 600px)': {
        fontSize: '32pt',
        lineHeight: '36pt',
        textAlign: 'center',
      },
    },
    h3: {
      fontSize: '48pt',
      lineHeight: '52pt',
      fontWeight: 400,
      fontFamily: 'ivyjournal,sans-serif',

      [breakpoints.down('xs')]: {
        fontSize: '32pt',
        lineHeight: '36pt',
      },
    },
    h4: {
      fontSize: '36pt',
      lineHeight: '42pt',
      fontWeight: 400,
      fontFamily: 'ivyjournal,sans-serif',
      [breakpoints.down('xs')]: {
        fontSize: '22px',
        lineHeight: '24pt',
      },
    },
    h5: {
      fontSize: '20pt',
      lineHeight: '27pt',
      fontWeight: 400,
      fontFamily: 'avenir,sans-serif',

      [breakpoints.down('xs')]: {
        fontSize: '15pt',
        lineHeight: '17pt',
      },
    },
    h6: {
      fontSize: '18pt',
      lineHeight: '25pt',
      fontWeight: 400,
      fontFamily: 'avenir,sans-serif',
    },
    subtitle1: {
      fontSize: '18pt',
      lineHeight: '25pt',
      fontWeight: 400,
    },
    body1: {
      fontSize: '18px',
      lineHeight: '32pt',
      fontFamily: 'Avenir',
      color: '#1C242A',
      [breakpoints.down('xs')]: {
        fontSize: '14pt',
        lineHeight: '18pt',
      },
    },
    body2: {
      fontFamily: 'Avenir',
      fontSize: '12pt',
      lineHeight: '18pt',
      fontWeight: 400,
    },
  },
};
