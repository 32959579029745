import { APPLICATION_STEP } from '@driva-development/driva-types';

const APPLICATION_ORDERED_STEPS = [
  APPLICATION_STEP.PersonalDetails,
  APPLICATION_STEP.VehicleLookup,
  APPLICATION_STEP.Employment,
  APPLICATION_STEP.Guarantor,
  APPLICATION_STEP.Expenses,
  APPLICATION_STEP.Assets,
  APPLICATION_STEP.Liabilities,
  APPLICATION_STEP.Submit,
];

export const isStepCompleted = (thisStep: APPLICATION_STEP, compareStep: APPLICATION_STEP): boolean =>
  APPLICATION_ORDERED_STEPS.findIndex((step) => step === thisStep) < APPLICATION_ORDERED_STEPS.findIndex((step) => step === compareStep);
