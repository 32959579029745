import React, { FC, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import styles from './currencyField.module.scss';

interface CurrencyFieldProps extends FormFieldProps {
  maxLength?: number;
  maxDecimalPlaces?: number;
}

export const CurrencyField: FC<CurrencyFieldProps> = ({
  name,
  title,
  subtitle,
  description,
  placeholder,
  helperText,
  maxLength,
  maxDecimalPlaces = 0,
  disabled = false,
}) => {
  const { clearErrors } = useFormContext();

  const ref = useRef<HTMLInputElement>(null);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.blur();
    }
  };

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <div className={styles.inputContainer}>
        <span className={styles.prefix}>$</span>
        <Controller
          name={name}
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => (
            <NumericFormat
              getInputRef={ref}
              inputMode={'decimal'}
              className={error ? styles.error : ''}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={maxDecimalPlaces}
              fixedDecimalScale={maxDecimalPlaces > 0}
              disabled={isSubmitting || disabled}
              onKeyDown={onKeyDown}
              onValueChange={({ value: newValue }) => {
                onChange({ target: { value: newValue ? +newValue : null } });
                clearErrors(name);
              }}
              data-cy={`currency-field-${name}`}
            />
          )}
        />
      </div>
    </FieldContainer>
  );
};
