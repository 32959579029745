import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import { QuoteValues } from 'types/quote';
import { RepaymentFrequency } from 'types/quote';
import { LoanOption } from 'types/quote';

import { ASSET_CONDITION, LOAN_CLASS } from '@driva-development/driva-types';

import { calculateLoanAmount, filterEligibleLenders, isPersonalLoanApp, isRefinanceApp } from '../utils/functions';

interface Quote {
  quoteValues: QuoteValues;
  loanOptions: LoanOption[];
}

interface QuoteContextProps {
  quoteValues: Partial<QuoteValues>;
  loanOptions: {
    firstLender: LoanOption;
    secondLender: LoanOption;
    medianLender: LoanOption;
    allLenderOptions: LoanOption[];
    otherLenderOptions: LoanOption[];
    eligibleLenderOptions: LoanOption[];
  };
  loanInfo: {
    loanUuid: string;
    totalLoanAmount: number;
    isCommercialLoan: boolean;
    isPersonalLoan: boolean;
    isRefinance: boolean;
    isBestRatePromise: boolean;
    isEligible: boolean;
  };
  setQuote: (quote: Quote) => void;
  resetQuote: () => void;
  repaymentFrequency: RepaymentFrequency;
  setRepaymentFrequency: (repaymentFrequency: RepaymentFrequency) => void;
  showOtherLoans: boolean;
  setShowOtherLoans: (showOtherLoans: boolean) => void;
  allowDesktopView: boolean;
}

const QuoteContext = createContext<QuoteContextProps>({
  quoteValues: {},
  loanInfo: {
    loanUuid: '',
    totalLoanAmount: 0,
    isCommercialLoan: false,
    isPersonalLoan: false,
    isRefinance: false,
    isBestRatePromise: false,
    isEligible: false,
  },
  loanOptions: {
    firstLender: {} as LoanOption,
    secondLender: {} as LoanOption,
    medianLender: {} as LoanOption,
    otherLenderOptions: [],
    allLenderOptions: [],
    eligibleLenderOptions: [],
  },
  repaymentFrequency: 'monthly',
  setRepaymentFrequency: () => undefined,
  setQuote: () => undefined,
  resetQuote: () => undefined,
  showOtherLoans: false,
  setShowOtherLoans: () => undefined,
  allowDesktopView: true,
});

QuoteContext.displayName = 'QuoteContext';

interface QuoteProviderProps {
  loanUuid: string;
  quote: {
    quoteValues: QuoteValues;
    loanOptions: LoanOption[];
  };
  options: {
    allowDesktopView?: boolean;
    enableBestRatePromise?: boolean;
  };
  children?: ReactNode;
}

export const QuoteProvider: FC<QuoteProviderProps> = ({
  quote: initialQuote,
  loanUuid,
  options: { allowDesktopView = true, enableBestRatePromise = true },
  children,
}) => {
  const [quote, setQuote] = useState<{ quoteValues: QuoteValues; loanOptions: LoanOption[] }>(initialQuote);
  const [repaymentFrequency, setRepaymentFrequency] = useState<RepaymentFrequency>('monthly');
  const [showOtherLoans, setShowOtherLoans] = useState<boolean>(false);

  const {
    quoteValues,
    quoteValues: { assetPrice, loanDeposit, loanClass, personalLoan, loanPurpose, assetCondition, warranty, otherAddons },
    loanOptions,
  } = quote;

  const eligibleLenders = filterEligibleLenders(loanOptions);

  const resetQuote = () => {
    setQuote(initialQuote);
    setRepaymentFrequency('monthly');
    setShowOtherLoans(false);
  };

  const providerValue = {
    quoteValues,
    loanInfo: {
      loanUuid,
      totalLoanAmount: calculateLoanAmount(assetPrice, loanDeposit, warranty, otherAddons),
      isCommercialLoan: loanClass === LOAN_CLASS.COMMERCIAL,
      isPersonalLoan: isPersonalLoanApp(personalLoan),
      isRefinance: isRefinanceApp(loanPurpose),
      isBestRatePromise: enableBestRatePromise && (isPersonalLoanApp(personalLoan) || assetCondition === ASSET_CONDITION.USED),
      isEligible: loanOptions[0]?.isEligible ? true : false,
    },
    loanOptions: {
      firstLender: loanOptions[0],
      secondLender: eligibleLenders[1],
      medianLender: eligibleLenders[Math.floor(eligibleLenders.length / 2)],
      allLenderOptions: loanOptions,
      otherLenderOptions: loanOptions.slice(1),
      eligibleLenderOptions: eligibleLenders,
    },
    setQuote,
    resetQuote,
    repaymentFrequency,
    setRepaymentFrequency,
    showOtherLoans,
    setShowOtherLoans,
    allowDesktopView,
  };

  return <QuoteContext.Provider value={providerValue}>{children}</QuoteContext.Provider>;
};

export const useQuote = () => useContext<QuoteContextProps>(QuoteContext);
