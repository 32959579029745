import { ThemeComponents } from './types';

export const MuiLinearProgress: ThemeComponents['MuiLinearProgress'] = {
  styleOverrides: {
    root: {
      border: '6px #1C242A solid',
      borderRadius: '15px',
      minHeight: '18px',
    },
    colorPrimary: {
      backgroundColor: '#9B9FA0',
    },
    barColorPrimary: {
      backgroundColor: '#A1E2C1',
      borderRadius: '20px',
    },
  },
};
