import React, { FC, useEffect } from 'react';

import styles from './trustPilot.module.css';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

interface ITrustPilot {}

export const TrustPilot: FC<ITrustPilot> = () => {
  const trustBoxRef = React.useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);

  return (
    <div className={styles.trustBar}>
      <div
        ref={trustBoxRef}
        className={'trustpilot-widget'}
        data-locale="en-US"
        data-template-id="5419b637fa0340045cd0c936"
        data-businessunit-id="5e4b32c580da5a0001aed9a1"
        data-style-height="20px"
        data-style-width="100%"
        data-theme="light"
      >
        <a href="https://www.trustpilot.com/review/driva.com.au">Trustpilot</a>
      </div>
    </div>
  );
};
