import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

import { redirectModalClasses, StyledRedirectModal } from '../../../../components/Redirects/styles';

import cross from './../../../../assets/img/cross.svg';

const PREFIX = 'ExistingQuoteModal';

const existingQuoteModalClasses = {
  modalRoot: `${PREFIX}-modalRoot`,
  modalBody: `${PREFIX}-modalBody`,
  modalHeader: `${PREFIX}-modalHeader`,
  modalText: `${PREFIX}-modalText`,
  modalButton: `${PREFIX}-modalButton`,
  modalWrapper: `${PREFIX}-modalWrapper`,
  imgBox: `${PREFIX}-imgBox`,
  crossImg: `${PREFIX}-crossImg`,
};

const StyledExistingQuoteModal = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 120,
  },
  [`& .${existingQuoteModalClasses.modalRoot}`]: {
    background: '#fff',
    padding: '15px',
    borderRadius: '5px',
    color: '#3E3F42',
    margin: '0 auto',
    maxWidth: 549,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    fontStyle: 'normal',
  },
  [`& .${existingQuoteModalClasses.modalBody}`]: {
    padding: '0px 30px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px',
    },
    textAlign: 'center',
  },
  [`& .${existingQuoteModalClasses.modalHeader}`]: {
    fontSize: '27px',
    lineHeight: '34px',
    paddingTop: 10,
    fontFamily: 'Ivyjournal,sans-serif',
  },
  [`& .${existingQuoteModalClasses.modalText}`]: {
    fontWeight: 500,
    fontSize: 17,
    paddingTop: '30px',
    fontFamily: 'Avenir',
  },
  [`& .${existingQuoteModalClasses.modalButton}`]: {
    maxWidth: 250,
    width: '100%',
    marginTop: 45,
  },
  [`& .${existingQuoteModalClasses.imgBox}`]: {
    alignSelf: 'end',
    paddingTop: 10,
    paddingRight: 10,
  },
  [`& .${existingQuoteModalClasses.crossImg}`]: {
    height: 16,
    width: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface ExistingQuoteModalProps {
  closeModal: any;
  showModal: boolean;
}

const ExistingQuoteModal: FC<ExistingQuoteModalProps> = ({ closeModal, showModal }) => (
  <StyledRedirectModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={showModal}
    onClose={closeModal}
    closeAfterTransition
    slotProps={{ backdrop: { timeout: 500 } }}
  >
    <div className={`${redirectModalClasses.modalWrapper} ${redirectModalClasses.modalWrapperBreakdown}`}>
      <StyledExistingQuoteModal>
        <Fade in={showModal}>
          <div className={existingQuoteModalClasses.modalRoot} data-cy="existing-quote-modal">
            <div onClick={closeModal} className={existingQuoteModalClasses.imgBox}>
              <img className={existingQuoteModalClasses.crossImg} src={cross} alt="" />
            </div>
            <div className={existingQuoteModalClasses.modalBody}>
              <div className={existingQuoteModalClasses.modalHeader}>Log in to continue where you've left off</div>
              <div className={existingQuoteModalClasses.modalText}>
                We notice that you already have an existing application in progress. Log in to continue where you left off.
              </div>
              <Button className={existingQuoteModalClasses.modalButton} variant="contained" onClick={closeModal} data-cy="existing-quote-modal-button">
                Log in to continue
              </Button>
            </div>
          </div>
        </Fade>
      </StyledExistingQuoteModal>
    </div>
  </StyledRedirectModal>
);

export default ExistingQuoteModal;
