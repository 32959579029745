import React, { FC, ReactNode, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Hidden } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import carStepShape from '../../assets/img/carStep.svg';
import loanDetailsStepShape from '../../assets/img/loanDetails.svg';
import logo from '../../assets/img/logo.svg';
import { matchTld } from '../../utils';
import { LinearProgress } from '../LinearProgress';

import mail from './../../assets/img/mail.svg';
import PhoneInTalkIcon from './../../assets/img/phone_icon.svg';
import LoginMobileTopBar from './LoginMobileTopBar';

import iconStyles from './../../assets/scss/iconStyles.module.scss';

const PREFIX = 'LoginLayout';

const loginLayoutClasses = {
  topBarContainer: `${PREFIX}-topBarContainer`,
  appBar: `${PREFIX}-appBar`,
  phoneWrapper: `${PREFIX}-phoneWrapper`,
  main: `${PREFIX}-main`,
  stepContainer: `${PREFIX}-stepContainer`,
  intoView: `${PREFIX}-intoView`,
  stepDesc: `${PREFIX}-stepDesc`,
  stepQuestion: `${PREFIX}-stepQuestion`,
  qa: `${PREFIX}-qa`,
  question: `${PREFIX}-question`,
  stepImg: `${PREFIX}-stepImg`,
  stepImgCar: `${PREFIX}-stepImgCar`,
  stepImgloanDetails: `${PREFIX}-stepImgloanDetails`,
  trustPilot: `${PREFIX}-trustPilot`,
  perks: `${PREFIX}-perks`,
  instantIcon: `${PREFIX}-instantIcon`,
  noImpactIcon: `${PREFIX}-noImpactIcon`,
  header: `${PREFIX}-header`,
  headerRight: `${PREFIX}-headerRight`,
  bottomBarContainer: `${PREFIX}-bottomBarContainer`,
};

const StyledLoginLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: '#A1E2C1',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  padding: 0,
  paddingTop: 0,
  [`& .${loginLayoutClasses.topBarContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    width: '75vw',
    maxWidth: 1150,
    padding: '0 30px',
    marginTop: '20px',
  },
  [`& .${loginLayoutClasses.appBar}`]: {
    width: '100vw',
    backgroundColor: '#A1E2C1',
    padding: '60px 0 0',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0px',
    alignContent: 'stretch',
    border: 0,
  },
  [`& .${loginLayoutClasses.phoneWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',

    color: '#1C252A',
    '& span.phone-icon': {
      width: '35px',
      height: '35px',
      background: '#000',
      textAlign: 'center',
      borderRadius: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      marginRight: '10px',
    },
  },
  [`& .${loginLayoutClasses.main}`]: {
    position: 'relative',
    padding: 0,
    paddingTop: '20px',
    flexBasis: 'auto',
    margin: 0,
  },
  [`& .${loginLayoutClasses.stepContainer}`]: {
    flexBasis: 'auto',
    margin: 0,
    opacity: '0',
  },
  [`& .${loginLayoutClasses.intoView}`]: {
    opacity: '1',
    transform: 'translateX(0)',
    transition: 'opacity 0.4s, transform 0.4s',
  },
  [`& .${loginLayoutClasses.stepDesc}`]: {
    fontSize: '44px',
    paddingBottom: '40px',
    lineHeight: '1.25em',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      fontSize: '35px',
      lineHeight: '1.25em',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
  },
  [`& .${loginLayoutClasses.stepQuestion}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '30px',
  },
  [`& .${loginLayoutClasses.qa}`]: {
    width: '35px',
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  [`& .${loginLayoutClasses.question}`]: {
    fontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  [`& .${loginLayoutClasses.stepImg}`]: {
    position: 'absolute',
    bottom: '80px',
    left: '0px',
    width: '220px',
    [theme.breakpoints.down('lg')]: {
      '&': {
        display: 'none',
      },
    },
  },
  [`& .${loginLayoutClasses.stepImgCar}`]: {
    [theme.breakpoints.up('lg')]: {
      background: `url(${carStepShape}) no-repeat -25px bottom`,
    },
  },
  [`& .${loginLayoutClasses.stepImgloanDetails}`]: {
    [theme.breakpoints.up('lg')]: {
      background: `url(${loanDetailsStepShape}) no-repeat -25px bottom`,
    },
  },
  [`& .${loginLayoutClasses.trustPilot}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: '40px',
  },
  [`& .${loginLayoutClasses.perks}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  [`& .${loginLayoutClasses.instantIcon}`]: {
    width: '19px',
    height: '27px',
    marginRight: '20px',
  },
  [`& .${loginLayoutClasses.noImpactIcon}`]: {
    width: '26px',
    marginRight: '10px',
  },
  [`& .${loginLayoutClasses.header}`]: {
    width: '100vw',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      maxHeight: 0,
    },
  },
  [`& .${loginLayoutClasses.headerRight}`]: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    fontSize: 14,

    '& img': {
      margin: '0 17px',
      height: 20,
      width: 20,
    },

    '& p': {
      fontWeight: 'bold',
    },

    '& span': {
      textDecoration: 'underline',
      fontSize: 14,
    },
  },
  [`& .${loginLayoutClasses.bottomBarContainer}`]: {
    width: '75vw',
    maxWidth: 1150,
    margin: '0 auto',
  },
}));

const LoginLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  document.body.classList.add('portal');

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [transition, setTransition] = useState(true);

  useEffect(() => {
    setTransition(false);
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, [pathname]);

  return (
    <StyledLoginLayout>
      <Hidden only={['lg', 'xl']}>
        <Grid container={true} className={loginLayoutClasses.header}>
          <LoginMobileTopBar />
        </Grid>
      </Hidden>

      <Hidden only={['xs', 'sm']}>
        <Grid container={true} className={loginLayoutClasses.header}>
          <AppBar className={loginLayoutClasses.appBar}>
            <div className={loginLayoutClasses.topBarContainer}>
              <div>
                <a href={matchTld('https://www.driva.com.au/')}>
                  <img src={logo} alt="" />
                </a>
              </div>
              <div className={loginLayoutClasses.headerRight}>
                <p>Need Help? </p>
                <a href={'tel:' + t('phone')} className={loginLayoutClasses.phoneWrapper}>
                  <img src={PhoneInTalkIcon} className={iconStyles.phoneIcon} alt="" />
                  <span>Call us</span>
                </a>
                <a href={'mailto:' + t('email.contact')} target="_top" className={loginLayoutClasses.phoneWrapper}>
                  <img src={mail} alt="" /> <span>Email us</span>
                </a>
              </div>
            </div>
          </AppBar>
        </Grid>
      </Hidden>

      <Container maxWidth={false} className={loginLayoutClasses.main}>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <div className={`${loginLayoutClasses.stepContainer} ${transition ? loginLayoutClasses.intoView : ''}`}>
              <Suspense fallback={<LinearProgress />}>{children}</Suspense>
            </div>
          </Grid>
        </Grid>
      </Container>
    </StyledLoginLayout>
  );
};

export default LoginLayout;
