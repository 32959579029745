import { ThemeComponents } from './types';

export const MuiFormControlLabel: ThemeComponents['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      marginLeft: '0px',
    },
    label: {
      marginLeft: '15px',
      fontSize: '14px',
      lineHeight: '1.4em',
    },
  },
};
