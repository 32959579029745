import React, { ReactNode, useEffect } from 'react';
import { DefaultValues, FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import { ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const defaultFormOptions: UseFormProps = {
  mode: 'onSubmit',
  reValidateMode: 'onSubmit',
  criteriaMode: 'firstError',
  shouldFocusError: true,
  shouldUnregister: true,
};

type FormProps<TFormValues> = {
  formId?: string;
  defaultValues: DefaultValues<TFormValues>;
  validationSchema: ZodType<TFormValues>;
  submitHandler: SubmitHandler<FieldValues>;
  errorHandler?: SubmitErrorHandler<FieldValues>;
  shouldResetErrorsOnBlur?: boolean;
  children?: ReactNode;
};

export const HookForm = <TFormValues extends FieldValues>({
  formId,
  defaultValues,
  validationSchema,
  submitHandler,
  errorHandler,
  shouldResetErrorsOnBlur,
  children,
}: FormProps<TFormValues>): JSX.Element => {
  const form = useForm({
    defaultValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    ...defaultFormOptions,
  });

  const { watch, clearErrors, handleSubmit } = form;

  useEffect(() => {
    const subscription = watch(() => {
      if (shouldResetErrorsOnBlur === true) {
        clearErrors();
      }
    });

    return () => subscription.unsubscribe();
  }, [shouldResetErrorsOnBlur, watch, clearErrors]);

  return (
    <FormProvider {...form}>
      <form id={formId} onSubmit={handleSubmit(submitHandler, errorHandler)}>
        {children}
      </form>
    </FormProvider>
  );
};
