import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';

import { LoaderContainer } from '../components/LoaderContainer/LoaderContainer';

interface LoaderContextProps {
  closeLoader: (options?: { delay: number }) => void;
  showLoader: (loader: ReactNode, autoClose?: { onClose: () => void; delay: number }) => void;
  isShowingLoader: boolean;
}

const LoaderContext = createContext<LoaderContextProps>({
  closeLoader: () => {},
  showLoader: () => {},
  isShowingLoader: false,
});

LoaderContext.displayName = 'LoaderContext';

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [loader, setLoader] = useState<ReactNode>(undefined);

  const showLoader = useCallback<LoaderContextProps['showLoader']>((newLoader, autoClose) => {
    setLoader(newLoader);

    if (autoClose != null) {
      setTimeout(() => {
        autoClose.onClose();
        setLoader(undefined);
      }, autoClose.delay);
    }
  }, []);

  const closeLoader = useCallback<LoaderContextProps['closeLoader']>((options) => {
    setTimeout(() => setLoader(undefined), options?.delay ?? 0);
  }, []);

  const isShowingLoader = loader != null;

  return (
    <LoaderContext.Provider value={{ showLoader, closeLoader, isShowingLoader }}>
      <LoaderContainer>{loader}</LoaderContainer>
      {children}
    </LoaderContext.Provider>
  );
};
