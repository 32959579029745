import { colors } from '@mui/material';

const white = '#FFFFFF';
const gray = '#F0F2EE';
const drivaGreen = '#A1E2C1';

export const palette = {
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100],
    green: drivaGreen,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A700,
    light: colors.blue.A400,
    green: drivaGreen,
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: '#CD2D2D',
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: gray,
    paper: white,
  },
  divider: colors.blueGrey[400],
  border: '#EAEDF3',
};
