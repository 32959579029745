import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { ASSESSMENT_STATUS, LOAN_APPLICATION_STATUS, LOAN_APPLICATION_STATUS_TEXT } from '@driva-development/driva-types';

import { dashMutate, dashQuery } from '../api';
import { AssessmentResult, AutoAssessmentStatus } from '../types/types';

import { useSessionContext } from './SessionContext';

const REFETCH_DELAY = 5000;
const REFETCH_LIMIT_STATUS_NONE = 10;
const REFETCH_LIMIT_STATUS_STARTED = 60;

const completedAssessmentStatuses = [
  ASSESSMENT_STATUS.PENDING,
  ASSESSMENT_STATUS.COMPLETE,
  ASSESSMENT_STATUS.CONFIRMED,
  ASSESSMENT_STATUS.INELIGIBLE,
  ASSESSMENT_STATUS.FAILED,
  ASSESSMENT_STATUS.TIMEOUT,
];

interface AssessmentContextProps {
  assessmentStatus: ASSESSMENT_STATUS | undefined;
  assessmentResult?: AssessmentResult;
  isAssessmentComplete: boolean;
  getAssessment: () => Promise<AutoAssessmentStatus>;
  startAssessment: (callback: () => void) => Promise<void>;
}

const AssessmentContext = createContext<AssessmentContextProps>({
  assessmentStatus: undefined,
  isAssessmentComplete: false,
  getAssessment: () => new Promise((resolve) => resolve({ assessmentStatus: ASSESSMENT_STATUS.NONE })),
  startAssessment: async () => {},
});

AssessmentContext.displayName = 'AssessmentContext';

export const useAssessment = () => React.useContext<AssessmentContextProps>(AssessmentContext);

export const AssessmentProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const {
    application: {
      loanUuid,
      customerLoanApplication,
      customerLoanApplication: { status },
    },
    setApplication,
  } = useSessionContext();

  const [assessmentStatus, setAssessmentStatus] = useState<ASSESSMENT_STATUS | undefined>();
  const [assessmentResult, setAssessmentResult] = useState<AssessmentResult | undefined>();

  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollCount, setPollCount] = useState<number>(0);

  const isAssessmentComplete = completedAssessmentStatuses.includes(assessmentStatus ?? ASSESSMENT_STATUS.NONE);

  useEffect(() => {
    if (assessmentStatus === ASSESSMENT_STATUS.STARTED) {
      setIsPolling(true);
    }
  }, [assessmentStatus]);

  useEffect(() => {
    if (isAssessmentComplete) {
      setIsPolling(false);
    }
  }, [isAssessmentComplete]);

  useEffect(() => {
    if (!isPolling) {
      return;
    }

    const pollCountLimit = assessmentStatus === ASSESSMENT_STATUS.STARTED ? REFETCH_LIMIT_STATUS_STARTED : REFETCH_LIMIT_STATUS_NONE;

    if (pollCount > pollCountLimit) {
      setIsPolling(false);
      setAssessmentStatus(ASSESSMENT_STATUS.TIMEOUT);
    }
  }, [pollCount, isPolling, assessmentStatus]);

  const getAssessmentStatusOnSuccess = (data: AutoAssessmentStatus): void => {
    if (data.assessmentStatus !== assessmentStatus) {
      setAssessmentStatus(data.assessmentStatus);
    }

    if (data.assessmentResult != null) {
      setAssessmentResult(data.assessmentResult);
    }

    if (data.applicationStatus != null && data.applicationStatus !== status) {
      setApplication({ customerLoanApplication: { ...customerLoanApplication, status: data.applicationStatus } });
    }

    if (isPolling) {
      setPollCount((prev) => prev++);
    }
  };

  const getAssessmentStatusOnError = (error: unknown): void => {
    console.error('Error refetching assessment status', error);
    setAssessmentStatus(ASSESSMENT_STATUS.FAILED);
  };

  const { refetch: getAssessmentStatus } = dashQuery<AutoAssessmentStatus, Error>(
    'getAssessmentStatus',
    { onSuccess: getAssessmentStatusOnSuccess, onError: getAssessmentStatusOnError, enabled: isPolling, refetchInterval: REFETCH_DELAY },
    { loanUuid }
  );

  const getAssessment = useCallback<AssessmentContextProps['getAssessment']>(async () => {
    const result = await getAssessmentStatus().catch((error) => {
      console.error('Error getting assessment status', error);
    });

    return result?.data ?? { assessmentStatus: ASSESSMENT_STATUS.NONE };
  }, [getAssessmentStatus]);

  const { mutateAsync: startAutoAssessment } = dashMutate<{}, unknown, unknown>('startAutoAssessment', { loanUuid });

  const startAssessment = useCallback<AssessmentContextProps['startAssessment']>(
    async (callback) => {
      if (assessmentStatus !== ASSESSMENT_STATUS.NONE) {
        console.error(`Cannot start assessment: assessment status is ${assessmentStatus}`);
        return;
      }

      if (status !== LOAN_APPLICATION_STATUS.AWAITING_DOCUMENTS) {
        console.error(`Cannot start assessment: application status is ${LOAN_APPLICATION_STATUS_TEXT[status as keyof typeof LOAN_APPLICATION_STATUS_TEXT]}`);
        return;
      }

      await startAutoAssessment({ loanUuid })
        .then(() => {
          setPollCount(0);
          setIsPolling(true);

          if (callback != null) {
            return callback();
          }
        })
        .catch((error) => {
          console.error('Error starting auto assessment', error);
          setAssessmentStatus(ASSESSMENT_STATUS.FAILED);
        });
    },
    [assessmentStatus, status, loanUuid, startAutoAssessment]
  );

  return (
    <AssessmentContext.Provider value={{ assessmentStatus, assessmentResult, isAssessmentComplete, getAssessment, startAssessment }}>
      {children}
    </AssessmentContext.Provider>
  );
};
