import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const HomePageRedirect: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href = t('website');
  }, [t]);

  return null;
};
