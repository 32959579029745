import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

import cross from '../../../../assets/img/cross.svg';
import { redirectModalClasses, StyledRedirectModal } from '../../../../components/Redirects/styles';

const PREFIX = 'NewQuoteModal';

const newQuoteModalClasses = {
  modalRoot: `${PREFIX}-modalRoot`,
  modalBody: `${PREFIX}-modalBody`,
  modalHeader: `${PREFIX}-modalHeader`,
  modalText: `${PREFIX}-modalText`,
  modalButton: `${PREFIX}-modalButton`,
  modalWrapper: `${PREFIX}-modalWrapper`,
  imgBox: `${PREFIX}-imgBox`,
  crossImg: `${PREFIX}-crossImg`,
};

const StyledNewQuoteModal = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 120,
  },
  [`& .${newQuoteModalClasses.modalRoot}`]: {
    background: '#fff',
    padding: '15px',
    borderRadius: '5px',
    color: '#3E3F42',
    margin: '0 auto',
    maxWidth: 549,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginBottom: 35,
    fontStyle: 'normal',
  },
  [`& .${newQuoteModalClasses.modalBody}`]: {
    padding: '0px 30px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px',
    },
    textAlign: 'center',
  },
  [`& .${newQuoteModalClasses.modalHeader}`]: {
    fontSize: '27px',
    lineHeight: '34px',
    paddingTop: 10,
    fontFamily: 'Ivyjournal,sans-serif',
  },
  [`& .${newQuoteModalClasses.modalText}`]: {
    fontWeight: 500,
    fontSize: 17,
    paddingTop: '30px',
    fontFamily: 'Avenir',
  },
  [`& .${newQuoteModalClasses.modalButton}`]: {
    width: 250,
    marginTop: 45,
  },
  [`& .${newQuoteModalClasses.imgBox}`]: {
    alignSelf: 'end',
    paddingTop: 10,
    paddingRight: 10,
  },
  [`& .${newQuoteModalClasses.crossImg}`]: {
    height: 16,
    width: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface NewQuoteModalProps {
  closeModal: any;
  showModal: boolean;
  userName: string;
}

/**
 * if customer didn't update status in 90 days, this modal is to remind them to get a new quote
 * @param closeModal
 * @param showModal
 * @param userName -customer first name
 *
 */
const NewQuoteModal: FC<NewQuoteModalProps> = ({ closeModal, showModal, userName }) => (
  <StyledRedirectModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={showModal}
    onClose={closeModal}
    closeAfterTransition
    slotProps={{ backdrop: { timeout: 500 } }}
  >
    <div className={`${redirectModalClasses.modalWrapper} ${redirectModalClasses.modalWrapperBreakdown}`}>
      <StyledNewQuoteModal>
        <Fade in={showModal}>
          <div className={newQuoteModalClasses.modalRoot} data-cy="new-quote-modal">
            <div onClick={() => closeModal()} className={newQuoteModalClasses.imgBox}>
              <img className={newQuoteModalClasses.crossImg} src={cross} alt="" />
            </div>
            <div className={newQuoteModalClasses.modalBody}>
              <div className={newQuoteModalClasses.modalHeader}>Hi {userName}, time to get your new quote!</div>
              <div className={newQuoteModalClasses.modalText}>We saw that you've started an application but it's been more than 2 months!</div>
              <div className={newQuoteModalClasses.modalText}>Things might have changed, so you will need to get a new quote to continue.</div>
              <Button className={newQuoteModalClasses.modalButton} variant="contained" onClick={() => closeModal()} data-cy="new-quote-modal-button">
                Get a new quote
              </Button>
            </div>
          </div>
        </Fade>
      </StyledNewQuoteModal>
    </div>
  </StyledRedirectModal>
);

export default NewQuoteModal;
