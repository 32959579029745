import React, { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import styles from './resetButton.module.scss';

interface ResetButtonProps {
  label: string;
}

export const ResetButton: FC<ResetButtonProps> = ({ label }) => {
  const { reset } = useFormContext();
  const { isSubmitting, isDirty } = useFormState();

  const clickHandler = () => reset();

  const isDisabled = isSubmitting || !isDirty;

  return (
    <button type="button" className={styles.button} onClick={clickHandler} disabled={isDisabled} data-cy={`${label}-button`}>
      {label}
    </button>
  );
};
