import React, { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { classNames } from '../../../utils';
import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import styles from './selectField.module.scss';

const isOptionsNumber = (options: SelectFieldProps['options']) => options.every(({ value }) => typeof value === 'number');

interface SelectFieldProps extends FormFieldProps {
  options: { value: string | number; label: string }[];
}

export const SelectField: FC<SelectFieldProps> = ({ name, title, subtitle, description, helperText, options, disabled = false }) => {
  const { register } = useFormContext();
  const { isSubmitting, errors } = useFormState();
  const isValueAsNumber = isOptionsNumber(options);

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <select
        {...register(name, { valueAsNumber: isValueAsNumber })}
        className={classNames(styles.selectField, errors[name] ? styles.error : '')}
        disabled={isSubmitting || disabled}
        data-cy={`select-field-${name}`}
      >
        {options.map(({ value, label }, index) => (
          <option key={`select-field-${index}`} value={value} data-cy={`select-field-${index}`}>
            {label}
          </option>
        ))}
      </select>
    </FieldContainer>
  );
};
