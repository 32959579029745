import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-fetch-backend';

import { currentRegion } from './utils/currentRegion';

const debugValue = process.env.REACT_APP_TRANSLATION_DEBUG_VALUE === 'true';

const CustomLanguageDetector: any = {
  type: 'languageDetector',
  async: false,
  detect: function () {
    const region = currentRegion();
    return 'en-' + region;
  },
};

i18n
  .use(Backend)
  .use(CustomLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-AU',
    debug: debugValue,

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?sha=' + process.env.REACT_APP_VERSION,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
