import React, { FC, ReactNode, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getCurrentUrl } from 'utils/getCurrentUrl';

import { HTTP_STATUS_CODE } from '@driva-development/driva-types';

import { POLLING_INTERVAL, SESSION_MANAGER_KEY, SESSION_RETURN_URL } from '../config/const';
import { SessionManagerContext } from '../context/SessionManagerContext';

const SessionManager: FC<{ children?: ReactNode }> = (props): JSX.Element => {
  const loanUuid = sessionStorage.getItem(SESSION_MANAGER_KEY);
  const [sessionLoanUuid, setSessionLoanUuid] = useState<string | null>(loanUuid);

  useEffect(() => {
    const checkSessionActive = async () => {
      await axios.get(`${process.env.PUBLIC_URL}/api/v2/auth/check-session-active`);
    };

    if (sessionLoanUuid) {
      const intervalId = setInterval(() => {
        checkSessionActive();
      }, POLLING_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [sessionLoanUuid]);

  useEffect(() => {
    if (sessionLoanUuid) {
      const httpForbiddenInterceptor = axios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error) => {
          if (error?.response?.status === HTTP_STATUS_CODE.FORBIDDEN) {
            sessionStorage.removeItem(SESSION_MANAGER_KEY);
            sessionStorage.setItem(SESSION_RETURN_URL, getCurrentUrl());
            window.location.href = `/login?uuid=${sessionLoanUuid}`;
          }
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.request.eject(httpForbiddenInterceptor);
        sessionStorage.removeItem(SESSION_MANAGER_KEY);
        sessionStorage.removeItem(SESSION_RETURN_URL);
      };
    }
  }, [sessionLoanUuid]);

  useEffect(() => {
    if (sessionLoanUuid) {
      sessionStorage.setItem(SESSION_MANAGER_KEY, sessionLoanUuid);
    } else {
      sessionStorage.removeItem(SESSION_MANAGER_KEY);
    }
  }, [sessionLoanUuid]);

  return <SessionManagerContext.Provider value={{ sessionLoanUuid, setSessionLoanUuid }}>{props.children}</SessionManagerContext.Provider>;
};

export default SessionManager;
