import React, { FC } from 'react';

import { AlertOctagon } from '../assets';

import styles from './errorMessage.module.scss';

interface ErrorMessageProps {
  name: string;
  errorMessage: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ name, errorMessage }) => (
  <div className={styles.errorMessage}>
    <img src={AlertOctagon} alt="" />
    <div data-cy={`error-message-${name}`}>{errorMessage}</div>
  </div>
);
