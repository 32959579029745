import React, { FC, ReactNode, useEffect, useState } from 'react';

import { classNames } from '../../utils';

import styles from './loaderContainer.module.scss';

const OVERLAY_TRANSITION_TIME = 250;
const LOADER_TRANSITION_TIME = 500;

interface LoaderContainerProps {
  children?: ReactNode;
}

export const LoaderContainer: FC<LoaderContainerProps> = ({ children }) => {
  const [loader, setLoader] = useState<ReactNode>(undefined);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [showContainer, setShowContainer] = useState<boolean>(false);

  useEffect(() => {
    const showLoader = children != null;
    const loaderTimeout = setTimeout(() => setLoader(children), showLoader ? 0 : LOADER_TRANSITION_TIME + OVERLAY_TRANSITION_TIME);
    const overlayTimeout = setTimeout(() => setShowOverlay(showLoader), showLoader ? 1 : LOADER_TRANSITION_TIME);
    const containerTimeout = setTimeout(() => setShowContainer(showLoader), showLoader ? OVERLAY_TRANSITION_TIME : 0);

    document.body.style.overflow = showLoader ? 'hidden' : 'visible';

    return () => {
      clearTimeout(loaderTimeout);
      clearTimeout(overlayTimeout);
      clearTimeout(containerTimeout);
      document.body.style.overflow = 'visible';
    };
  }, [children]);

  useEffect(() => {
    if (loader != null && children != null) {
      setShowContainer(false);
    }
  }, [loader, children]);

  return loader != null ? (
    <div className={classNames(styles.overlayContainer, showOverlay ? styles.showOverlay : styles.hideOverlay)}>
      <div className={classNames(styles.loaderContainer, showContainer ? styles.showContainer : styles.hideContainer)}>
        <div className={styles.loader}>{loader}</div>
      </div>
    </div>
  ) : null;
};
