import { LoanOption } from 'types/quote';

import { LOAN_PURPOSE, PERSONAL_LOAN_PURPOSE } from '@driva-development/driva-types';

import { currencyFormatter } from './currencyFormatter';

export const formatNumAsCurrency = (num: number): string => currencyFormatter.format(num ? num : 0);

export const formatCurrency = (num: number | string = 0): string => `${formatNumAsCurrency(Number(Number(num).toFixed(0)))}`;

const formatDecimal = (num: number = 0, decimalPlaces: number = 2): string => num.toFixed(decimalPlaces);

export const formatYears = (num: number | string = 0): string => (Number(num) === 1 ? '1 year' : `${num} years`);

export const formatPercentage = (num: number = 0): string => `${formatDecimal(num)}%`;

export const calculateLoanAmount = (assetPrice: number = 0, deposit: number = 0, warranty: number = 0, otherAddons: number = 0): number =>
  assetPrice - deposit + warranty + otherAddons;

export const calculateLoanMonths = (loanTerm: number = 0): number => loanTerm * 12;

export const calculateLoanFortnights = (loanTerm: number = 0): number => loanTerm * 26;

export const calculateLoanWeeks = (loanTerm: number = 0): number => loanTerm * 52;

export const convertMonthlyToFortnightly = (value: number = 0): number => Math.round((value * 12) / 26);

export const convertMonthlyToWeekly = (value: number = 0): number => Math.round((value * 12) / 52);

export const filterEligibleLenders = (lenders: LoanOption[]): LoanOption[] => lenders.filter((lender) => lender.isEligible);

const calculateBalloonPayment = (assetPrice: number, deposit: number, balloonPercentage: number, warranty: number, otherAddons: number) =>
  calculateLoanAmount(assetPrice, deposit, warranty, otherAddons) * (balloonPercentage / 100);

export const calculateLifetimeRepayment = (
  monthly: number = 0,
  term: number = 0,
  assetPrice: number = 0,
  deposit: number = 0,
  balloonPercentage: number = 0,
  warranty: number = 0,
  otherAddons: number = 0
): number => monthly * calculateLoanMonths(term) + calculateBalloonPayment(assetPrice, deposit, balloonPercentage, warranty, otherAddons);

export const capitaliseFirstLetter = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

export const filterUniqueNonEligibleCode = (allLenderOptions: LoanOption[]): number[] => {
  const unique: number[] = [];
  allLenderOptions.forEach((loanOption: LoanOption) => {
    if (loanOption.nonEligibleCode && !unique.includes(loanOption.nonEligibleCode)) {
      unique.push(loanOption.nonEligibleCode);
    }
  });
  return unique;
};

// Number of milliseconds in a day: 1000 * 60 * 60 * 24 = 86400000
export const numDaysBetween = (d1: Date, d2: Date): number => Math.floor(Math.abs(d1.getTime() - d2.getTime()) / 86400000);

export const isRefinanceApp = (loanPurpose: string | undefined): boolean =>
  [LOAN_PURPOSE.REFINANCE, LOAN_PURPOSE.BUSINESS_REFINANCE].includes(loanPurpose as LOAN_PURPOSE);

const MAX_NEW_VEHICLE_YEARS = 3;

export const isVehicleAgeUsed = (vehicleYear: number): boolean => new Date().getFullYear() - vehicleYear > MAX_NEW_VEHICLE_YEARS;

export const isPersonalLoanApp = (personalLoan: 0 | PERSONAL_LOAN_PURPOSE | undefined): boolean => personalLoan != null && personalLoan > 0;
