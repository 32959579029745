import React, { FC } from 'react';
import { FieldValues } from 'react-hook-form';

import { HookForm, SubmitButton } from '../Form';
import { NumericField } from '../Form';
import { ToolTipCard } from '../ToolTipCard/ToolTipCard';

import { mobileVerificationSchema } from './MobileVerification.schema';

import styles from './mobileVerification.module.scss';

export const MobileVerification: FC = () => {
  const tooltipLabel = 'Why do we need to confirm your mobile and date of birth?';
  const tooltipContent = 'We will use your number and date of birth to confirm your identity and pre-fill your details from your previous application.';

  const submitHandler = (formValues: FieldValues) => {
    return formValues;
  };

  const resendCodeHandler = () => {
    return true;
  };

  return (
    <HookForm defaultValues={{}} validationSchema={mobileVerificationSchema} submitHandler={submitHandler}>
      <div className={styles.mobileScreen}>
        <div className={styles.mobileContainer}>
          <div className={styles.mobileHeaderContainer}>
            <div className={styles.mobileHeader}>We’ve sent a code to your mobile phone to login</div>
            <div className={styles.mobileTxtContainer}>
              <div className={styles.mobileInfo}>It was sent to 04 **** 101</div>
              <div>valid for 5 min</div>
            </div>
          </div>
          <div className={styles.otpContainer}>
            <NumericField
              name="otp"
              title="6 digit code"
              placeholder="000000"
              maxLength={6}
              thousandSeparator={false}
              autoFocus={true}
              inputMode={'numeric'}
              allowLeadingZeroes={true}
            />
          </div>
          <div className={styles.submitBtnContainer}>
            <SubmitButton label="Continue" />
          </div>
          <div className={styles.resendCodeContainer}>
            <div className={styles.actionLink} onClick={resendCodeHandler}>
              Resend code
            </div>
          </div>
        </div>
        <div className={styles.actionLinkLgContainer}>
          <div className={styles.actionLinkLg}>Not your number?</div>
        </div>
        <ToolTipCard label={tooltipLabel} content={tooltipContent} />
      </div>
    </HookForm>
  );
};
