import React, { CSSProperties, FC, ReactNode, useState } from 'react';
import { classNames } from 'utils';

import helpCircle from '../../assets/img/helpCircle.svg';

import style from './toolTip.module.scss';

interface IToolTip {
  title?: ReactNode;
  position?: 'right' | 'bottom' | 'left';
  width?: number;
  children: ReactNode;
}

export const ToolTip: FC<IToolTip> = ({ width, title, position, children }) => {
  const [active, setActive] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout>();

  const showTip = () => {
    setShowToolTip(true);
    clearTimeout(hideTimeout);
    setTimeout(() => setActive(true), 10);
  };

  const hideTip = () => {
    setActive(false);
    const hideTimeout = setTimeout(() => setShowToolTip(false), 500);
    setHideTimeout(hideTimeout);
  };

  const handleClick = () => {
    if (!active) {
      setShowToolTip(true);
      setTimeout(() => setActive(true), 10);
    } else {
      setActive(false);
      const hideTimeout = setTimeout(() => setShowToolTip(false), 500);
      setHideTimeout(hideTimeout);
    }
  };

  const toolTipStyle: CSSProperties = width !== undefined ? { width: `${width}px` } : {};
  const bubblePosition = !position ? style.right : style[position];

  return (
    <div className={style.toolTip}>
      {title ? (
        <div onMouseEnter={showTip} onMouseLeave={hideTip} onClick={handleClick}>
          {title}
        </div>
      ) : (
        <img src={helpCircle} className={style.helpCircle} alt="?" onMouseEnter={showTip} onMouseLeave={hideTip} onClick={handleClick} />
      )}
      {showToolTip && (
        <div
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
          className={classNames(style.bubble, active ? style.active : style.inactive, bubblePosition)}
          style={toolTipStyle}
        >
          {children}
        </div>
      )}
    </div>
  );
};
