import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import classes from './trustBoxCarousel.module.scss';
declare global {
  interface Window {
    Trustpilot: any;
  }
}

const TrustBoxCarousel = () => {
  const ref = React.useRef(null);
  const midmatches = useMediaQuery('(max-width:550px)');

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className={classes.trustPilotWidgetContainer}>
      <div
        ref={ref}
        className={`trustpilot-widget ${classes.trustPilotWidget}`}
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="5e4b32c580da5a0001aed9a1"
        data-style-height={midmatches ? 300 : 270}
        data-style-width="100%"
        data-theme="light"
        data-stars="3,4,5"
        data-review-languages="en"
      >
        <a href="https://www.trustpilot.com/review/driva.com.au" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </div>
  );
};

export default TrustBoxCarousel;
