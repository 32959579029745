import React, { FC, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { classNames } from '../../../utils';
import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import styles from './numericField.module.scss';

interface NumericFieldProps extends FormFieldProps {
  maxLength?: number;
  thousandSeparator?: boolean;
  allowLeadingZeroes?: boolean;
  maxDecimalPlaces?: number;
  autoFocus?: boolean;
  inputMode?: 'tel' | 'numeric' | 'decimal';
}

export const NumericField: FC<NumericFieldProps> = ({
  name,
  title,
  subtitle,
  description,
  placeholder,
  helperText,
  maxLength,
  thousandSeparator = true,
  allowLeadingZeroes = false,
  maxDecimalPlaces = 0,
  disabled = false,
  autoFocus = false,
  inputMode = 'tel',
}) => {
  const { clearErrors } = useFormContext();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.blur();
    }
  };

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <Controller
        name={name}
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => (
          <NumericFormat
            getInputRef={ref}
            inputMode={inputMode}
            value={value}
            className={classNames(styles.numericField, error ? styles.error : '')}
            placeholder={placeholder}
            maxLength={maxLength}
            thousandSeparator={thousandSeparator}
            allowNegative={false}
            allowLeadingZeros={allowLeadingZeroes}
            decimalScale={maxDecimalPlaces}
            fixedDecimalScale={false}
            autoFocus={autoFocus}
            disabled={isSubmitting || disabled}
            onKeyDown={onKeyDown}
            onValueChange={({ value: newValue }) => {
              if (newValue && !allowLeadingZeroes) {
                onChange({ target: { value: Number(newValue) } });
              } else {
                onChange({ target: { value: newValue ? newValue : null } });
              }

              clearErrors(name);
            }}
            data-cy={`numeric-field-${name}`}
          />
        )}
      />
    </FieldContainer>
  );
};
