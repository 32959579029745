export const YES_NO_DROPDOWN = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const TOOLTIP_HOVER_DURATION_IN_MS = 4500;

export const POLLING_INTERVAL = 30000;
export const SESSION_MANAGER_KEY = 'sessionLoanUuid';
export const SESSION_RETURN_URL = 'sessionReturnUrl';
