import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';

import { ModalContainer } from '../components/ModalContainer/ModalContainer';

export interface ModalContextProps {
  isBottomSheet?: boolean;
  showModal: (modal: ReactNode, options?: { isBottomSheet?: boolean; lockModal?: boolean; onModalClose?: () => void }) => void;
  closeModal: (options?: { force?: boolean }) => void;
  lockModal: (isLocked: boolean) => void;
}

export const ModalContext = createContext<ModalContextProps>({
  showModal: () => undefined,
  closeModal: () => undefined,
  lockModal: () => undefined,
});

interface ModalProviderProps {
  children?: ReactNode;
}

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ReactNode>(undefined);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isBottomSheet, setBottomSheet] = useState<boolean>(false);
  const [onModalClose, setOnModalClose] = useState<() => void>();

  const lockModal = useCallback((locked: boolean) => {
    setIsLocked(locked);
  }, []);

  const showModal = useCallback<ModalContextProps['showModal']>((modal, options) => {
    setModal(undefined);
    setTimeout(() => setModal(modal), 1);
    setIsLocked(options?.lockModal === true);
    setBottomSheet(options?.isBottomSheet === true);
    setOnModalClose(() => options?.onModalClose);
    document.body.style.overflow = 'hidden';
  }, []);

  const closeModal = useCallback<ModalContextProps['closeModal']>(
    (options) => {
      const isAllowClose = !isLocked || options?.force === true;

      if (isAllowClose && onModalClose != null) {
        onModalClose();
      }

      if (isAllowClose) {
        setModal(undefined);
        document.body.style.overflow = 'visible';
      }
    },
    [isLocked, onModalClose]
  );

  return (
    <ModalContext.Provider value={{ isBottomSheet, showModal, closeModal, lockModal }}>
      {children}
      <ModalContainer>{modal}</ModalContainer>
    </ModalContext.Provider>
  );
};

ModalContext.displayName = 'ModalContext';

export const useModal = () => useContext<ModalContextProps>(ModalContext);
