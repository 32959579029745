import React, { FC, useLayoutEffect } from 'react';

import LoaderImg from '../../assets/img/bars.svg';

import classes from './loading.module.scss';

interface LoadingProps {
  fullPageLoader?: boolean;
  noMargins?: boolean;
}

const Loading: FC<LoadingProps> = (props) => {
  const { fullPageLoader, noMargins } = props;

  useLayoutEffect(() => {
    if (fullPageLoader) {
      document.body.classList.add(classes.fullPageBackground);
    }

    return () => {
      document.body.classList.remove(classes.fullPageBackground);
    };
  }, [fullPageLoader]);

  return (
    <div className={fullPageLoader ? classes.fullPage : noMargins ? classes.noMargins : classes.root}>
      <img src={LoaderImg} alt="" />
    </div>
  );
};

export default Loading;
