import React, { FC, KeyboardEventHandler, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { classNames } from '../../../utils';
import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import styles from './textField.module.scss';

interface TextFieldProps extends FormFieldProps {
  maxLength?: number;
}

export const TextField: FC<TextFieldProps> = ({ name, title, subtitle, description, placeholder, helperText, maxLength, disabled = false }) => {
  const { clearErrors } = useFormContext();

  const ref = useRef<HTMLInputElement>(null);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.blur();
    }
  };

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <Controller
        name={name}
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitting } }) => (
          <input
            ref={ref}
            type="text"
            className={classNames(styles.textField, error ? styles.error : '')}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={isSubmitting || disabled}
            onKeyDown={onKeyDown}
            onChange={(event) => {
              onChange(event);
              clearErrors(name);
            }}
            data-cy={`text-field-${name}`}
          />
        )}
      />
    </FieldContainer>
  );
};
