import { ThemeComponents } from './types';

export const MuiOutlinedInput: ThemeComponents['MuiOutlinedInput'] = {
  styleOverrides: {
    adornedStart: {
      paddingLeft: 0,
    },
    input: {
      padding: '14px 20px 11px',
    },
    inputAdornedStart: {
      paddingLeft: 46,
    },
    notchedOutline: {
      display: 'none',
    },
  },
};
