import React, { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import deepEqual from 'deep-equal';

import { Spinner } from '../assets';

import styles from './submitButton.module.scss';

interface SubmitButtonProps {
  label: string;
  loadingLabel?: string;
}

export const SubmitButton: FC<SubmitButtonProps> = ({ label, loadingLabel: processingLabel = label }) => {
  const { isSubmitting, isDirty, defaultValues } = useFormState();
  const values = useWatch();

  const isDisabled = isSubmitting || !isDirty || deepEqual(values, defaultValues);

  return (
    <button type="submit" className={styles.button} disabled={isDisabled} data-cy={`${label}-button`}>
      {isSubmitting ? <img src={Spinner} alt="" className={styles.spinner} /> : null}
      {isSubmitting ? processingLabel : label}
    </button>
  );
};
