import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';

import Loading from './components/Loading/Loading';
import { getDeviceContext } from './lib/launchDarkly/launchDarkly';
import { AnalyticsProvider, ReferralContext, ViewportProvider } from './context';
import Routes from './routes';
import { dashTheme } from './theme';

import './styles/fonts.css';
import './styles/main.scss';

/*
 * https://docs.datadoghq.com/logs/error_tracking/browser_and_mobile/?tab=browser
 */
datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DD_SERVICE || '',
  env: process.env.REACT_APP_DD_ENV || 'local',
  version: process.env.REACT_APP_DD_VERSION || '0.0.0',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sampleRate: 100,
});

window.onerror = function (message, source, lineno, colno, error) {
  datadogLogs.logger.error(error?.message || '', {
    error: { stack: error?.stack },
  });
};

const queryClient = new QueryClient();

const referral = window.location.search !== '' ? window.location.search : '';

const App: React.FC = () => (
  <ReferralContext.Provider value={referral.trim()}>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={dashTheme}>
            <ViewportProvider>
              <AnalyticsProvider>
                <Suspense fallback={<Loading />}>
                  <Routes />
                </Suspense>
              </AnalyticsProvider>
            </ViewportProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </HelmetProvider>
    </QueryClientProvider>
  </ReferralContext.Provider>
);

const clientSideID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;

// When running cypress need to reliably set featureflags, but cypress does not support intercept of websockets (streaming) so disable.
export default withLDProvider({
  clientSideID: clientSideID ? clientSideID : '',
  options: { streaming: window['Cypress'] == null },
  context: getDeviceContext(),
})(App);
