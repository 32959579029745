import { ThemeComponents } from './types';

export const MuiInputAdornment: ThemeComponents['MuiInputAdornment'] = {
  styleOverrides: {
    root: {},
    positionStart: {
      position: 'absolute',
      top: 25,
      left: 14,
      fontSize: 16,
      marginBottom: 0,
      '& p': {
        position: 'absolute',
        left: 6,
        fontSize: 16,
        backgroundColor: 'transparent',
      },
    },
  },
};
