import { INCOME_FREQUENCY, REF_RESIDENCY_STATUS, refEmploymentStatusMap, refLivingSituationMap } from '@driva-development/driva-types';

import { EXPENSES_FREQUENCY } from '../views/Portal/Expenses/types';
import { IFormikValue } from '../views/shared/types/IFormikValue';

const RESIDENCY_MAP = {
  [REF_RESIDENCY_STATUS.CITIZEN]: 'citizen',
  [REF_RESIDENCY_STATUS.PR]: 'pr',
  [REF_RESIDENCY_STATUS.VISA]: 'visa',
};

const normaliseAmountToMonthly = (amount: string, frequency: string) => {
  if (!frequency) {
    return 0;
  }

  switch (frequency.toLocaleLowerCase()) {
    case INCOME_FREQUENCY.YEARLY.toLocaleLowerCase():
      return parseInt(amount) / 12 || 0;
    case INCOME_FREQUENCY.MONTHLY.toLocaleLowerCase():
      return parseInt(amount) || 0;
    case INCOME_FREQUENCY.FORTNIGHTLY.toLocaleLowerCase():
      return (parseInt(amount) * 26) / 12 || 0;
    case INCOME_FREQUENCY.WEEKLY.toLocaleLowerCase():
      return parseInt(amount) * 4.33 || 0;
    default:
      return 0;
  }
};

export const monthlyExpenses = (values: IFormikValue) => {
  const EXPENSES_FREQUENCY_WEEKLY = EXPENSES_FREQUENCY.WEEKLY.toLowerCase();

  const {
    expensesNumAdultsOnTenancy,
    expensesRentFrequency,
    expensesRent,
    expensesFoodFrequency,
    expensesFood,
    expensesUtilitiesFrequency,
    expensesUtilities,
    expensesEntertainmentFrequency,
    expensesEntertainment,
    expensesHealthEducationFrequency,
    expensesHealthEducation,
    expensesTransportFrequency,
    expensesTransport,
    expensesOtherFrequency,
    expensesOther,
  } = values;
  return [
    parseInt(
      expensesNumAdultsOnTenancy
        ? (expensesRentFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesRent * 4.33 : expensesRent) / parseInt(expensesNumAdultsOnTenancy)
        : expensesRentFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY
        ? expensesRent * 4.33
        : expensesRent
    ) || 0,
    parseInt(expensesFoodFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesFood * 4.33 : expensesFood) || 0,
    parseInt(expensesUtilitiesFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesUtilities * 4.33 : expensesUtilities) || 0,
    parseInt(expensesEntertainmentFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesEntertainment * 4.33 : expensesEntertainment) || 0,
    parseInt(expensesHealthEducationFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesHealthEducation * 4.33 : expensesHealthEducation) || 0,
    parseInt(expensesTransportFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesTransport * 4.33 : expensesTransport) || 0,
    parseInt(expensesOtherFrequency?.toLowerCase() === EXPENSES_FREQUENCY_WEEKLY ? expensesOther * 4.33 : expensesOther) || 0,
  ].reduce((a, b) => a + b);
};

export const formatAnalyticsPayload = (context: any) => {
  try {
    const { application } = context || {};
    const { applicationData, loanUuid } = application || {};
    const {
      referrer,
      partnerName,
      commIndustry,
      commCompanyType,
      commNumberOfDirectors,
      vehicleYear,
      vehicleState,
      vehicleMake,
      vehicleModel,
      buyingThrough,
      totalDependants,
      employmentDurationMonths,
      relationshipStatus,
      propertyAddressSuburb,
      propertyAddressState,
      propertyAddressTownCity,
      propertyAddressPostCode,
      jobTitle,
      currentEmployer,
      employmentDurationYears,
      expensesRent,
      expensesRentFrequency,
      expensesRentPercentage,
      expensesFood,
      expensesFoodFrequency,
      expensesUtilities,
      expensesUtilitiesFrequency,
      expensesEntertainment,
      expensesEntertainmentFrequency,
      expensesHealthEducation,
      expensesHealthEducationFrequency,
      expensesTransport,
      expensesTransportFrequency,
      expensesOther,
      expensesOtherFrequency,
      assetsCash,
      assetsCashBool,
      homeContents,
      homeContentsBool,
      existingVehicles,
      existingVehiclesBool,
      assetsInvestments,
      assetsInvestmentsBool,
      assetsOther,
      assetsOtherBool,
      creditCards,
      personalLoans,
      vehicleLoans,
      mortgages,
      payLaters,
      otherLiabilities,
      incomeAmount,
      incomeFrequency,
      partnerIncomeAmount,
      partnerIncomeFrequency,
      otherIncomes,
      guarantorIncomeAmount,
      guarantorIncomeFrequency,
      guarantorPartnerIncomeAmount,
      guarantorPartnerIncomeFrequency,
      guarantorOtherIncomes,
      employmentStatus,
      livingSituation,
      businessUse,
      residencyStatus,
    } = applicationData || {};

    const assets: Record<string, { isBool: boolean; amount: number }> = {
      cash: {
        isBool: assetsCashBool,
        amount: parseInt(assetsCash),
      },
      home: {
        isBool: homeContentsBool,
        amount: parseInt(homeContents),
      },
      vehicles: {
        isBool: existingVehiclesBool,
        amount: parseInt(existingVehicles),
      },
      investments: {
        isBool: assetsInvestmentsBool,
        amount: parseInt(assetsInvestments),
      },
      others: {
        isBool: assetsOtherBool,
        amount: parseInt(assetsOther),
      },
    };

    let totalAssetValue = 0;
    for (const key of Object.keys(assets)) {
      if (assets[key].isBool) {
        totalAssetValue = totalAssetValue + assets[key].amount;
      }
    }

    const expenses = {
      expensesRentPercentage,
      expensesRentFrequency,
      expensesRent,
      expensesFoodFrequency,
      expensesFood,
      expensesUtilitiesFrequency,
      expensesUtilities,
      expensesEntertainmentFrequency,
      expensesEntertainment,
      expensesHealthEducationFrequency,
      expensesHealthEducation,
      expensesTransportFrequency,
      expensesTransport,
      expensesOtherFrequency,
      expensesOther,
    };

    return {
      quoteUuid: loanUuid,
      source: referrer,
      partner: partnerName,
      employmentStatus: refEmploymentStatusMap[employmentStatus as keyof typeof refEmploymentStatusMap],
      livingSituation: refLivingSituationMap[livingSituation as keyof typeof refLivingSituationMap],
      businessUse,
      companyIndustry: commIndustry,
      companyType: commCompanyType,
      companyNumberOfDirectors: commNumberOfDirectors,
      vehicleYear,
      vehicleState,
      vehicleMake,
      vehicleModel,
      buyingThrough,
      residencyStatus: RESIDENCY_MAP[residencyStatus as keyof typeof RESIDENCY_MAP],
      dependents: totalDependants,
      employmentDurationMonths,
      relationshipStatus,
      userSuburb: propertyAddressSuburb,
      userTownCity: propertyAddressTownCity,
      userState: propertyAddressState,
      userPostCode: propertyAddressPostCode,
      jobTitle,
      currentEmployer,
      currentEmployerTime: `${employmentDurationYears} years and ${employmentDurationMonths} months`,
      totalIncome:
        normaliseAmountToMonthly(incomeAmount, incomeFrequency) +
        normaliseAmountToMonthly(partnerIncomeAmount, partnerIncomeFrequency) +
        (otherIncomes?.reduce((sum: number, otherIncome: any) => {
          return Number(sum) + normaliseAmountToMonthly(otherIncome.amount, otherIncome.frequency);
        }, 0) ?? 0),
      corporateTotalIncome:
        normaliseAmountToMonthly(guarantorIncomeAmount, guarantorIncomeFrequency) +
        normaliseAmountToMonthly(guarantorPartnerIncomeAmount, guarantorPartnerIncomeFrequency) +
        (guarantorOtherIncomes?.reduce((sum: number, otherIncome: any) => {
          return Number(sum) + normaliseAmountToMonthly(otherIncome.amount, otherIncome.frequency);
        }, 0) ?? 0),
      totalExpenses: monthlyExpenses(expenses),
      rentalExpenses: expensesRentPercentage
        ? (normaliseAmountToMonthly(expensesRent, expensesRentFrequency) * parseInt(expensesRentPercentage)) / 100
        : normaliseAmountToMonthly(expensesRent, expensesRentFrequency),
      foodExpenses: normaliseAmountToMonthly(expensesFood, expensesFoodFrequency),
      utilityExpenses: normaliseAmountToMonthly(expensesUtilities, expensesUtilitiesFrequency),
      entertainmentExpenses: normaliseAmountToMonthly(expensesEntertainment, expensesEntertainmentFrequency),
      healthEducationExpenses: normaliseAmountToMonthly(expensesHealthEducation, expensesHealthEducationFrequency),
      transportExpenses: normaliseAmountToMonthly(expensesTransport, expensesTransportFrequency),
      otherExpenses: normaliseAmountToMonthly(expensesOther, expensesOtherFrequency),
      totalAssetValue,
      cashOnHand: assetsCash,
      homeContentsValue: homeContents,
      existingVehiclesValue: existingVehicles,
      investmentValue: assetsInvestments,
      otherAssetValue: assetsOther,
      numberOfCreditCards: creditCards?.length,
      numberOfPersonalLoans: personalLoans?.length,
      numberOfMortgages: mortgages?.length,
      numberOfPayLaterAccounts: payLaters?.length,
      // TODO : Atm, this is boolean in the system
      numberOfVehicles: vehicleLoans,
      numberOfOtherLiabilities: otherLiabilities?.length,
    };
  } catch (err: any) {
    console.error('error formatAnalyticsPayload', err.message);
  }
};

export const capitalizeWords = (text: string): string => {
  // Empty strings and undefined result in true: return.
  // Strings with length >= 1 result in false: continue.
  if (!text) {
    return text;
  }

  // This regex matches the first character of each lower-case word and converts it to upper-case.
  // Example: "this is an example of lower-case teXt" -> "This Is An Example Of Lower-Case TeXt"
  return text.toLowerCase().replace(/(\b[a-z](?!\s))/g, (match: string) => match.toUpperCase());
};

export const isManualResidentialAddress = (
  address?: string,
  addressUnit?: string,
  addressStreetNumber?: string,
  addressStreet?: string,
  addressSuburb?: string,
  addressTownCity?: string,
  addressState?: string,
  addressPostCode?: string
): boolean => {
  return !!(!address && (addressUnit || addressStreetNumber || addressStreet || addressSuburb || addressTownCity || addressState || addressPostCode));
};

type Duration = number | string | undefined;

interface AddressDurations {
  years: Duration;
  months: Duration;
  previousYears: Duration;
  previousMonths: Duration;
}

type IsShowAddressForm = (addressDurations: AddressDurations) => boolean;

export const isShowAddressForm: IsShowAddressForm = ({ years, months, previousYears, previousMonths }) => {
  if (years == null && months == null) {
    return true;
  }

  if (years === '' && months === '') {
    return true;
  }

  if (Number(years) >= 3) {
    return false;
  }

  if (previousYears == null && previousMonths == null) {
    return true;
  }

  if (previousYears === '' && previousMonths === '') {
    return true;
  }

  return false;
};

const SMALL_LOAN_AMOUNT = 15000;
export const isSmallLoan = (assetPrice: number | undefined, deposit: number | undefined): boolean => {
  if (assetPrice == null || deposit == null) {
    return false;
  }

  return assetPrice - deposit <= SMALL_LOAN_AMOUNT;
};
