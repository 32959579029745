import React, { FC, useEffect } from 'react';

interface MicroFrontendInterface {
  name: string;
  host: string;
  options?: any;
}

export const MicroFrontend: FC<MicroFrontendInterface> = ({ name, host, options }) => {
  const render = `render${name}`;
  const unmount = `unmount${name}`;
  const containerId = `container${name}`;
  const scriptId = `micro-frontend-script-${name}`;

  useEffect(() => {
    const renderMicroFrontend = () => {
      (window as any)[render]?.(options);
    };

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
    } else {
      fetch(`${host}/manifest.json`)
        .then((res) => res.json())
        .then((manifest) => {
          manifest['index.html']?.css?.forEach((file: string) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${host}/${file}`;
            link.media = 'screen';
            document.head.appendChild(link);
          });

          manifest['index.html']?.imports?.forEach((importName: string) => {
            const importScript = document.createElement('script');
            importScript.crossOrigin = '';
            importScript.type = 'module';
            importScript.src = `${host}/${manifest[importName].file}`;
            document.head.appendChild(importScript);
          });

          const script = document.createElement('script');
          script.id = scriptId;
          script.crossOrigin = '';
          script.type = 'module';
          script.src = `${host}/${manifest['index.html']?.file}`;
          script.onload = () => {
            renderMicroFrontend();
          };

          document.head.appendChild(script);
        });
    }

    return () => {
      (window as any)[unmount]?.();
    };
  }, [host, render, unmount, scriptId, containerId, options]);

  return <main id={containerId} />;
};
