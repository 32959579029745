import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import { SUPPORTED_REGION } from '@driva-development/driva-types';

import 'core-js/stable';
import './i18n';

import { currentRegion } from './utils/currentRegion';
import App from './App';

if (process.env.REACT_APP_CLUSTER_NAME !== 'local') {
  let gtmId;
  switch (currentRegion()) {
    case SUPPORTED_REGION.NZ:
      gtmId = 'GTM-M3T9J9JZ';
      break;
    case SUPPORTED_REGION.AU:
    default:
      gtmId = 'GTM-WBX285N';
      break;
  }
  TagManager.initialize({
    gtmId,
  });
}

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
