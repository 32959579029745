import { ThemeComponents } from './types';

export const MuiInputBase: ThemeComponents['MuiInputBase'] = {
  styleOverrides: {
    root: {
      width: '100%',
    },
    input: {
      padding: '14px 20px 11px',
      backgroundColor: '#FFFFFF',
      borderRadius: '5px!important',
      color: '#263238',
      border: '1px solid #EAEDF3',
      transition: 'border 0.3s',
      fontSize: '16px',
      fontFamily: 'avenir',
      '&:focus': {
        border: '1px solid #fceb45',
      },
      '&:disabled': {
        backgroundColor: '#E9EAED',
      },
      lineHeight: 'normal',
      height: '19px',
      minHeight: 'auto',
    },
    inputAdornedStart: {
      padding: '14px 0 11px 45px !important',
      marginRight: 0,
    },
  },
};
