import { ThemeComponents } from './types';

export const MuiListItem: ThemeComponents['MuiListItem'] = {
  styleOverrides: {
    root: {
      paddingTop: 0,
      margin: 0,
    },
  },
};
