export enum EXPENSES_FREQUENCY {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
}

export interface IFormExpenses {
  name: string;
  title: string;
  tipTitle?: string;
  placeholder?: string;
}

export interface IExpenses {
  expensesRent: string;
  expensesNumAdultsOnTenancy: string;
  expensesRentFrequency: string;
  expensesRentPercentage: string;
  expensesFood: string;
  expensesFoodFrequency: string;
  expensesUtilities: string;
  expensesUtilitiesFrequency: string;
  expensesEntertainment: string;
  expensesEntertainmentFrequency: string;
  expensesHealthEducation: string;
  expensesHealthEducationFrequency: string;
  expensesTransport: string;
  expensesTransportFrequency: string;
  expensesOther: string;
  expensesOtherFrequency: string;
  confirmedExpenseAccuracy?: boolean;
}
