import { ThemeComponents } from './types';

export const MuiDrawer: ThemeComponents['MuiDrawer'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      border: 'none',
    },
    paperAnchorDockedLeft: {
      borderRight: 'none',
    },
  },
};
