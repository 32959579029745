import React, { FC, useState } from 'react';
import { classNames } from 'utils';

import styles from './toolTipBubble.module.scss';

interface ToolTipBubbleProps {
  content: React.ReactNode;
  children: React.ReactNode;
  direction?: string;
}

export const ToolTipBubble: FC<ToolTipBubbleProps> = ({ content, children, direction = 'bottom' }) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout>();

  const showTip = () => {
    setShowToolTip(true);
    clearTimeout(hideTimeout);
  };

  const hideTip = () => {
    const hideTimeout = setTimeout(() => setShowToolTip(false), 100);
    setHideTimeout(hideTimeout);
  };

  return (
    <div className={styles.toolTipBubble} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {showToolTip && <div className={classNames(styles.bubble, styles[direction])}>{content}</div>}
    </div>
  );
};
