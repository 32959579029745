import { createBreakpoints } from '@mui/system';

import { ThemeComponents } from './types';

const breakpoints = createBreakpoints({});

export const MuiInputLabel: ThemeComponents['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      fontSize: '16px',
      fontWeight: '700',
      fontFamily: 'avenir',
      color: '#1C242A',
      marginBottom: '0',
      '&$focused': {
        color: '#000',
      },
      '&$error': {
        color: '#000',
      },
      '&$shrink': {
        transform: 'translate(0px, -2px) scale(1)',
        fontSize: '16px',
        [breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
    },
    animated: {
      // marginBottom: 5,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      lineHeight: 1.5,
      [breakpoints.down('sm')]: {
        marginBottom: 3,
      },
      whiteSpace: 'normal',
    },
  },
};
