import React, { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

import styles from './fieldContainer.module.scss';

interface FieldContainerProps {
  name: string;
  title?: ReactNode;
  subtitle?: string;
  description?: string;
  helperText?: string;
  children: ReactNode;
}

export const FieldContainer: FC<FieldContainerProps> = ({ name, title, subtitle, description, helperText, children }) => (
  <Controller
    name={name}
    render={({ fieldState: { error } }) => (
      <div className={styles.fieldContainer}>
        <div>
          {title != null ? <label className={styles.title}>{title}</label> : null}
          {subtitle != null ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
        {description != null ? <div className={styles.description}>{description}</div> : null}
        {children}
        {helperText != null && error == null ? <div className={styles.helperText}>{helperText}</div> : null}
        {error != null ? <ErrorMessage name={name} errorMessage={error.message ?? ''} /> : null}
      </div>
    )}
  />
);
