import { ThemeComponents } from './types';

export const MuiNativeSelect: ThemeComponents['MuiNativeSelect'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
    select: {
      borderRadius: 5,
      paddingLeft: 20,
      paddingTop: 14,
      paddingBottom: 14,
      border: '1px solid #EAEDF3',
      transition: 'border 0.3s',
      maxWidth: 'fill-available',
      '&:focus': {
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        border: '1px solid #fceb45',
      },
    },
    icon: {
      right: '15px',
    },
  },
};
