import React, { FC } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Grid, InputLabel, styled, Tooltip, useMediaQuery } from '@mui/material';

import { TOOLTIP_HOVER_DURATION_IN_MS } from '../config/const';

interface IFormTitle {
  title?: string;
  tipTitle?: string;
  shrinkTitle?: boolean;
}

const TitleContainer = styled(Box)(() => ({
  margin: '10px 0 5px 0',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const TitleLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: 'Avenir',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '1.5em',
  color: '#1C242A',
  whiteSpace: 'normal',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
  },
}));

const TitleHelpIcon = styled(HelpIcon)(() => ({
  width: '18px',
  height: '18px',
  marginLeft: '5px',
}));

const FormTitle: FC<IFormTitle> = ({ title, tipTitle, shrinkTitle }) => {
  const midMatches = useMediaQuery('(max-width:960px)');

  return (
    <>
      <Grid container direction="row" alignItems="center" alignContent="center" justifyContent="flex-start">
        <TitleContainer>
          {title && (
            <TitleLabel shrink={shrinkTitle}>
              {title}
              {tipTitle && (
                <Tooltip
                  arrow
                  title={tipTitle}
                  placement={midMatches ? 'bottom-end' : 'right'}
                  style={{ marginRight: 10 }}
                  enterTouchDelay={0}
                  leaveTouchDelay={TOOLTIP_HOVER_DURATION_IN_MS}
                >
                  <TitleHelpIcon />
                </Tooltip>
              )}
            </TitleLabel>
          )}
        </TitleContainer>
      </Grid>
    </>
  );
};

export default FormTitle;
