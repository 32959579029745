import { ThemeComponents } from './types';

export const MuiToggleButton: ThemeComponents['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      backgroundColor: '#FFFFFF!important',
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: '#FFFFFF!important',
        },
      },
    },
  },
};
