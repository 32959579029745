import { ThemeComponents } from './types';

export const MuiSwitch: ThemeComponents['MuiSwitch'] = {
  styleOverrides: {
    root: {
      margin: '0 10px 0 -6px',
      width: 56,
      height: 39,
      padding: 8,
      '.Mui-checked': {
        '& + .MuiSwitch-track': {
          background: 'linear-gradient(to right, #34AA44, #34AA44)',
          '&:before': {
            opacity: 1,
          },
          '&:after': {
            opacity: 0,
          },
        },
      },
    },
    switchBase: {
      padding: 8,
      color: '#ff6a00',
      '&.Mui-checked': {
        color: '#185a9d',
        transform: 'translateX(16px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)',
        },
      },
    },
    colorPrimary: {
      '&.Mui-checked': {
        color: '#A1E2C1',
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: '#FFFFFF',
        '& + $track': {
          backgroundColor: '#fceb45',
          opacity: 1,
        },
      },
    },
    thumb: {
      margin: '1px 0 0 2px',
      width: 20,
      height: 20,
      backgroundColor: '#fff',
      '&.Mui-checked': {
        backgroundColor: '#fff',
      },
    },
    track: {
      background: 'linear-gradient(to right, #EAEDF3, #EAEDF3)',
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
    },
    input: {
      marginRight: '10px',
    },
  },
};
