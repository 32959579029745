import React, { FC, Suspense, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from '../../components';
import { BasicTopBar } from '../../components';

import styles from './basicLayout.module.scss';

export const BasicLayout: FC = () => {
  useLayoutEffect(() => {
    document.body.classList.add(styles.dash);

    return () => {
      document.body.classList.remove(styles.dash);
    };
  }, []);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.topPanel}>
        <BasicTopBar />
      </div>
      <div id="mainBodyContainer" className={styles.mainBodyContainer}>
        <div className={styles.mainContentContainer}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};
