import { ThemeComponents } from './types';

export const MuiTooltip: ThemeComponents['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'avenir',
      color: '#FFFFFF',
    },
  },
};
