import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';

import { matchTld } from '../utils';

interface ApiListProps {
  [key: string]: {
    url: string;
    method: 'get' | 'post' | 'delete' | 'patch' | 'put';
    refreshFunc?: string;
    enabled?: boolean;
    isAsync?: boolean;
    headers?: string[];
    timeout?: number;
  };
}

// get - query, GET - mutation
const apiList: ApiListProps = {
  currentUser: {
    url: '/api/v2/auth/current_user',
    method: 'post',
  },
  checkUserExist: {
    url: '/api/v2/check-user-exist',
    method: 'post',
  },
  login: {
    url: '/api/v2/auth/login',
    method: 'post',
  },
  missingDetails: {
    url: '/api/v2/missing-details/:uuid',
    method: 'post',
  },
  getMissingDetailsApplication: {
    url: '/api/v2/missing-details/:uuid/:loanOptionPublicID',
    method: 'get',
  },
  generateSmsCode: {
    url: '/api/v2/auth/sms-code',
    method: 'post',
  },
  generateEmailAuth: {
    url: '/api/v2/auth/email-code',
    method: 'post',
  },
  getApplication: {
    url: '/api/v2/get-application/:uuid',
    method: 'get',
  },
  recalculate: {
    url: '/api/v2/recalculate/:uuid',
    method: 'post',
  },
  selectLenderAndLogin: {
    url: '/api/v2/select-lender/:uuid',
    method: 'post',
  },
  updateSelectedLender: {
    url: '/api/v2/update-selection/:uuid',
    method: 'post',
  },
  updateApplication: {
    url: '/api/v2/portal/application/:uuid',
    method: 'post',
  },
  createPartial: {
    url: '/api/portal/partial',
    method: 'post',
  },
  uploadFileToS3: {
    url: '/api/portal/upload',
    method: 'post',
  },
  updateApplicationUploads: {
    url: '/api/portal/uploads/:uuid',
    method: 'put',
  },
  getDocuments: {
    url: '/api/v2/files/documents/:uuid',
    method: 'get',
  },
  updateFiles: {
    url: '/api/v2/files/:uuid',
    method: 'put',
  },
  getRevisionId: {
    url: '/api/v2/files/:uuid/revisionId',
    method: 'post',
  },
  deleteFileOnS3: {
    url: '/api/v2/files/:uuid?label=:label&deletedBy=:deletedBy',
    method: 'delete',
  },
  uploadLoanApplicationDynamicRequests: {
    url: '/api/v2/dynamicRequests/upload/:uuid',
    method: 'post',
  },
  getAllLoanApplicationDynamicRequests: {
    url: '/api/v2/dynamicRequests/:uuid',
    method: 'get',
  },
  getDynamicRequestsCount: {
    url: '/api/v2/dynamicRequests/:uuid/count',
    method: 'get',
  },
  getPartialQuote: {
    url: '/api/v2/partial/partial-quote/:uuid',
    method: 'get',
  },
  wipeSellerInfo: {
    url: '/api/portal/wipe-seller/:uuid',
    method: 'post',
  },
  updateSellerInfo: {
    url: '/api/v2/portal/update-seller/:uuid',
    method: 'post',
  },
  getPdsData: {
    url: '/api/v2/get-pds/:uuid',
    method: 'get',
    isAsync: true,
  },
  updateApplicationFiles: {
    url: '/api/v2/portal/update-files/:uuid',
    method: 'post',
  },
  reCalculateOnQuoteData: {
    url: '/api/recalculate-quote/:uuid',
    method: 'post',
  },
  logout: {
    url: '/api/v2/auth/logout',
    method: 'post',
  },
  vehicleEnquiry: {
    url: '/api/v2/portal/:loanUuid/vehicle/enquiry',
    method: 'post',
  },
  vehicleSearch: {
    url: '/api/v2/portal/:loanUuid/vehicle/search',
    method: 'get',
    isAsync: true,
  },
  referral: {
    url: '/api/v2/referral/:loanUuid',
    method: 'get',
  },
  referralConsent: {
    url: '/api/v2/referral/:loanUuid/consent',
    method: 'post',
  },
  getInsuranceApplication: {
    url: '/api/v2/portal/insuranceApplication',
    method: 'get',
  },
  getAssessmentStatus: {
    url: '/api/v2/portal/auto-assessment/:loanUuid',
    method: 'get',
  },
  startAutoAssessment: {
    url: '/api/v2/portal/auto-assessment/:loanUuid',
    method: 'post',
  },
  confirmLenderSwitch: {
    url: '/api/v2/portal/auto-assessment/:loanUuid/confirmLenderSwitch',
    method: 'post',
  },
  runAutoAssessmentLite: {
    url: '/api/v2/portal/auto-assessment-lite/:loanUuid',
    method: 'post',
  },
  switchLender: {
    url: '/api/v2/switch-lender/:loanUuid',
    method: 'post',
  },
};

interface Params {
  [key: string]: unknown;
}

const callFunc = async (funcName: string, params?: Params, data?: any) => {
  try {
    const { url, method, headers, timeout } = apiList[funcName];
    const queryParams: Params = {};
    const headerParams: Record<string, string | number | boolean> = {};

    const requestUrl = params
      ? Object.entries(params).reduce((reducedUrl, [key, value]) => {
          if (reducedUrl.indexOf(`:${key}`) >= 0) {
            return reducedUrl.replace(`:${key}`, value as string);
          }

          if (headers && headers.indexOf(key) >= 0) {
            headerParams[key] = value as string | number | boolean;
          } else {
            queryParams[key] = value;
          }

          return reducedUrl;
        }, url)
      : url;

    const config: AxiosRequestConfig = {
      url: matchTld(process.env.REACT_APP_SERVER_BASE_URL ?? '') + requestUrl,
      method,
      data,
      params: queryParams,
      headers: new AxiosHeaders(headerParams),
      timeout,
    };

    const res = await axios(config);

    return res.data.data;
  } catch (err: any) {
    const cause = err?.response != null ? ({ status: err.response.status } as any) : undefined;

    if (err?.response?.data?.error?.displayMessage) {
      throw new Error(err.response.data.error.displayMessage, { cause });
    }

    if (err?.response?.data?.error?.message) {
      throw new Error(err.response.data.error.message, { cause });
    }

    if (err?.message) {
      throw new Error(err.message);
    }

    throw new Error(err);
  }
};

interface QueryOptions<Data> {
  enabled: boolean;
  retry?: number | boolean;
  refetchInterval?: number | false;
  onSuccess?: UseQueryOptions<Data>['onSuccess'];
  onError?: UseQueryOptions<Data>['onError'];
}

const Query = <Data, Error>(funcName: string, { enabled, retry = false, refetchInterval, onSuccess, onError }: QueryOptions<Data>, params?: Params) => {
  const queryFunction = () => callFunc(funcName, params);

  return useQuery<Data, Error, Data>([funcName, params], queryFunction, {
    // disable retry to avoid 4 times API call if failed
    retry,
    // disable refetchOnWindowFocus to avoid unnecessary API call on focus
    refetchOnWindowFocus: false,
    enabled,
    refetchInterval,
    refetchIntervalInBackground: !!refetchInterval,
    onSuccess,
    onError,
  });
};

const Mutation = <Data, Error, Variables>(funcName: string, params?: Params, retry: number | boolean = false) =>
  useMutation<Data, Error, Variables>((postData: any) => callFunc(funcName, params, postData), { retry });

export const dashApi = (
  funcName: string,
  params?: Params,
  enabled: boolean = true,
  refetchInterval: number | false = false,
  retry: number | boolean = false
): any => {
  const { method, isAsync } = apiList[funcName];

  if (method.toLowerCase() === 'get' && !isAsync) {
    return Query(funcName, { enabled, refetchInterval, retry }, params);
  }

  return Mutation(funcName, params, retry);
};

export const dashQuery = <Data = unknown, Error = unknown>(funcName: string, options: QueryOptions<Data>, params?: Params) =>
  Query<Data, Error>(funcName, options, params);

export const dashMutate = <Data = unknown, Error = unknown, Variables = void>(funcName: string, params?: Params, retry: number | boolean = false) =>
  Mutation<Data, Error, Variables>(funcName, params, retry);
