import { SUPPORTED_REGION } from '@driva-development/driva-types';

import { getTestRegion, isTestMode } from './';

export function currentRegion(): SUPPORTED_REGION {
  if (isTestMode()) {
    return getTestRegion() as SUPPORTED_REGION;
  }

  if (window.location.href.includes('localhost')) {
    return (process.env.REACT_APP_LOCALHOST_REGION as SUPPORTED_REGION) ?? SUPPORTED_REGION.AU;
  }

  if (window.location.href.includes('driva.co.nz')) {
    return SUPPORTED_REGION.NZ;
  }

  return SUPPORTED_REGION.AU;
}
