import React, { FC, forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

interface NumberFormatCustomProps {
  thousandSeparator: boolean;
  allowLeadingZeros: boolean;
  inputRef: React.Ref<any>;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  autoFocus?: boolean;
}

const NumberFormatCustom: FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, onChange, thousandSeparator, allowLeadingZeros, autoFocus, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      allowLeadingZeros={allowLeadingZeros}
      decimalScale={0}
      valueIsNumericString
      autoFocus={autoFocus}
      type="tel"
    />
  );
};

export const NumberFormatCustomWithThousandSeparator = forwardRef<typeof NumericFormat, NumberFormatCustomProps>((props, ref) => (
  <NumberFormatCustom {...props} inputRef={ref} thousandSeparator={true} allowLeadingZeros={false} />
));
NumberFormatCustomWithThousandSeparator.displayName = 'NumberFormatCustomWithThousandSeparator';

export const NumberFormatCustomWithoutThousandSeparator = forwardRef<typeof NumericFormat, NumberFormatCustomProps>((props, ref) => (
  <NumberFormatCustom {...props} inputRef={ref} thousandSeparator={false} allowLeadingZeros={true} />
));
NumberFormatCustomWithoutThousandSeparator.displayName = 'NumberFormatCustomWithoutThousandSeparator';
