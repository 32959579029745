import React, { ReactNode } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

type WatchFormFieldProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>[];
  children: (value: TFieldValues) => ReactNode;
};

export const WatchFormField = <TFieldValues extends FieldValues>({ name, children }: WatchFormFieldProps<TFieldValues>): JSX.Element => (
  <>
    {children(
      useWatch<TFieldValues, FieldPath<TFieldValues>[]>({ name }).reduce((acc, value, index) => ({ ...acc, [name[index]]: value }), {} as TFieldValues)
    )}
  </>
);
