import { REFERRAL_LOAN_PARTNER_STATUS, REJECTION_CODES } from '@driva-development/driva-types';

import { ReferralResponse } from '../types/types';

import { axiosRetry } from './axiosRetry';
import { matchTld } from './matchTld';

const MAX_RETRIES = 2;
const RETRY_DELAY = 3000;

const { CUSTOMER_PENDING, REFERRAL_SENT_TO_PARTNER, UNDER_ASSESSMENT } = REFERRAL_LOAN_PARTNER_STATUS;

interface Referral {
  declinePath: string;
  referralStatus?: string;
}

export const getReferral = async (loanUuid: string, rejectedCode?: REJECTION_CODES, maxRetries: number = MAX_RETRIES): Promise<Referral> => {
  const referralResponse = await axiosRetry<{ data: ReferralResponse }>(
    { method: 'GET', baseURL: matchTld(process.env.REACT_APP_SERVER_BASE_URL ?? ''), url: `/api/v2/referral/${loanUuid}` },
    {
      shouldRetry: (data) => !data?.data.success || data?.data.referralStatus === UNDER_ASSESSMENT,
      maxRetries,
      retryDelay: RETRY_DELAY,
      retryOnError: true,
    }
  ).catch((error) => {
    console.error('Error retrieving decline referral status: ', error);
    return undefined;
  });

  const unsuccessfulPath = rejectedCode != null ? `/unsuccessful/${loanUuid}` : '/unsuccessful';
  const referralPath = `/referral/${loanUuid}`;

  if (referralResponse == null) {
    return { declinePath: unsuccessfulPath };
  }

  const {
    data: {
      data: { referralStatus },
    },
  } = referralResponse;

  const declinePath = referralStatus === CUSTOMER_PENDING || referralStatus === REFERRAL_SENT_TO_PARTNER ? referralPath : unsuccessfulPath;

  return { declinePath, referralStatus };
};
