import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Link, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import logo from '../../../assets/img/logo.svg';
import PhoneInTalkIcon from '../../../assets/img/phone_icon.svg';

import iconStyles from '../../../assets/scss/iconStyles.module.scss';

const PREFIX = 'LoginMobileTopBar';

const loginMobileTopBarClasses = {
  mobileBarContents: `${PREFIX}-mobileBarContents`,
  mobileLogo: `${PREFIX}-mobileLogo`,
  rightHandLink: `${PREFIX}-rightHandLink`,
};

const StyledLoginMobileTopBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#A1E2C1',
  height: 60,
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    height: 60,
    top: 0,
  },
  border: 0,

  [`& .${loginMobileTopBarClasses.mobileBarContents}`]: {
    color: '#000',
    width: '100vw',
    maxWidth: 750,
    margin: '0 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      top: 0,
    },
  },
  [`& .${loginMobileTopBarClasses.mobileLogo}`]: {
    height: 20,
    width: 99,
  },
  [`& .${loginMobileTopBarClasses.rightHandLink}`]: {
    textDecoration: 'underline',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      textDecoration: 'none',
      alignItems: 'center',
      '& img': {
        marginRight: 10,
      },
    },
  },
}));

const LoginMobileTopBar: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledLoginMobileTopBar position="fixed">
      <Toolbar>
        <Box className={loginMobileTopBarClasses.mobileBarContents}>
          <Link href="/">
            <img className={loginMobileTopBarClasses.mobileLogo} src={logo} alt="" />
          </Link>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Link href={'tel:' + t('phone')} color="inherit" className={loginMobileTopBarClasses.rightHandLink}>
              <img src={PhoneInTalkIcon} className={iconStyles.phoneIcon} alt="" />
              {t('phone')}
            </Link>
          </div>
        </Box>
      </Toolbar>
    </StyledLoginMobileTopBar>
  );
};

export default LoginMobileTopBar;
