import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

/*
 * Relevant resources:
 * https://mitchgavan.com/code-splitting-react-safely/
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  useEffect(() => {
    if (!error?.message) {
      return;
    }

    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.location.reload();
    }

    //Dev Note: When deployment happens, there is a brief window where chunks are served with the wrong MIME type. Reloading the window works around this.
    if (
      /Refused to execute script from '.*\.chunk.js' because its MIME type \('text\/html'\) is not executable, and strict MIME type checking is enabled/.test(
        error.message
      )
    ) {
      window.location.reload();
    }
  }, [error]);

  return null;
};

export default ErrorFallback;
