import { ThemeComponents } from './types';

export const MuiMenuItem: ThemeComponents['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      height: '50px',
      padding: '20px 145px 20px 20px',
      borderBottom: '1px solid #EAEDF3',
    },
  },
};
