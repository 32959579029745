import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { ANALYTICS_TIMEOUT } from '../lib/analytics/constants';
import { LD_CONTEXT_KIND } from '../lib/launchDarkly/constants';
import { getDeviceContext } from '../lib/launchDarkly/launchDarkly';

interface AnalyticsContextProps {
  isIdentified: boolean;
  getContext: () => LDSingleKindContext | undefined;
  setContext: (ldContext: LDSingleKindContext) => void;
}

const AnalyticsContext = createContext<AnalyticsContextProps>({
  isIdentified: false,
  getContext: () => undefined,
  setContext: () => undefined,
});

AnalyticsContext.displayName = 'AnalyticsContext';

export const useAnalytics = () => useContext<AnalyticsContextProps>(AnalyticsContext);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const ldClient = useLDClient();

  const [userContext, setUserContext] = useState<LDSingleKindContext | undefined>(undefined);
  const [isIdentified, setIsIdentified] = useState<boolean>(false);

  const setContext = useCallback<(context: LDSingleKindContext) => Promise<void>>(
    (context) =>
      new Promise<void>((resolve) => {
        if (ldClient == null) {
          // eslint-disable-next-line no-console
          console.info('LaunchDarkly client is not initialised!');
          return;
        }

        const timeout = setTimeout(() => {
          // eslint-disable-next-line no-console
          console.info('Timed out identifying user');
          setIsIdentified(true);
        }, ANALYTICS_TIMEOUT);

        setUserContext(context);

        ldClient
          .waitUntilGoalsReady()
          .then(async () => {
            await ldClient.flush();
            await ldClient.identify({
              kind: LD_CONTEXT_KIND.MULTI,
              user: context,
              device: getDeviceContext(),
            });
            await ldClient.flush();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.info('Error identifying user', error);
          })
          .finally(() => {
            setIsIdentified(true);
            clearTimeout(timeout);
            resolve();
          });
      }),
    [ldClient]
  );

  const getContext = useCallback<() => LDSingleKindContext | undefined>(() => {
    if (ldClient == null) {
      // eslint-disable-next-line no-console
      console.info('LaunchDarkly client is not initialised!');
      return;
    }

    return userContext;
  }, [ldClient, userContext]);

  return ldClient != null ? <AnalyticsContext.Provider value={{ isIdentified, getContext, setContext }}>{children}</AnalyticsContext.Provider> : null;
};
