import React from 'react';
export interface IWhiteLabelStyle {
  primaryColor?: string;
  primaryTextColor?: string;
  secondaryColor?: string;
  secondaryTextColor?: string;
  containerBox?: {
    border: string | number;
    padding: string;
  };
  stepsButtons?: {
    marginBottom: string | number;
    paddingBottom: string | number;

    footerNextButton: {
      borderRadius: number;
      padding: string | number;
      fontSize: string | number;
      fontWeight: number;
      lineHeight: string;
    };
  };
}

type Globals = '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset';

type FlexDirectionProperty = Globals | 'column' | 'column-reverse' | 'row' | 'row-reverse';
export interface IButtonStyles {
  marginBottom?: string;
  paddingBottom?: string;
  fontWeight?: number;
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: FlexDirectionProperty;
  mobile?: {
    marginTop?: string;
  };
  backButton?: {
    marginLeft?: string;
  };
  footerNextButton?: {
    mobile?: {
      padding?: string;
    };
  };
  submitButton?: {
    borderRadius?: number;
    padding?: string | number;
    margin?: string | number;
    fontSize?: string | number;
    fontWeight?: number;
    lineHeight?: string;
    height?: number | undefined;
    width?: string;
    mobile?: {
      fontSize?: string | number;
      fontWeight?: number;
      width?: string | undefined;
      padding?: string | undefined;
      margin?: string | undefined;
      height?: number | undefined;
    };
  };
}

export const whiteLabelStyle: IWhiteLabelStyle = {
  primaryColor: '#a1e2c1',
  primaryTextColor: '#000',
  secondaryColor: '#FFF39C',
  secondaryTextColor: '#000',
  containerBox: {
    border: 0,
    padding: '16px 0',
  },
  stepsButtons: {
    marginBottom: 0,
    paddingBottom: 40,

    footerNextButton: {
      borderRadius: 6,
      padding: '13px 25px',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
};

export const WhiteLabelContext = React.createContext({});
