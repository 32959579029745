import { BUYING_THROUGH, REF_BUYING_THROUGH } from '@driva-development/driva-types';

export const BREAKPOINTS = {
  MOBILE_EXTRA_SMALL: 320,
  MOBILE_SMALL: 375,
  MOBILE: 450,
  TABLET_SMALL: 580,
  TABLET: 790,
  DESKTOP: 1024,
  DESKTOP_LARGE: 1280,
  DESKTOP_EXTRA_LARGE: 1440,
};

export const MAXIMUM_REQUEST_RETRIES = 3;

export const PRIVATE_SALE_FEE = 59;

export const BUYING_THROUGH_MAP = {
  [REF_BUYING_THROUGH.DEALER]: BUYING_THROUGH.DEALER,
  [REF_BUYING_THROUGH.PRIVATE]: BUYING_THROUGH.PRIVATE,
  [REF_BUYING_THROUGH.STILL_DECIDING]: BUYING_THROUGH.STILL_DECIDING,
};

export const REGION_COOKIE = 'region'; // shared with marketing site

export const MAX_UPLOAD_FILES = parseInt(process.env.REACT_APP_MAX_UPLOAD_FILES ?? '5');
