import { DynamicDocumentActionType, LOAN_CLASS, SUPPORTED_REGION } from '@driva-development/driva-types';

import { DIGITAL_BANK_STATEMENT_REFERRAL_TYPE } from '../views/Portal/constants';

export const getBankStatementReferralCodeByDynamicDocActionType = (
  dynamicDocActionType: DynamicDocumentActionType | undefined,
  loanClass: LOAN_CLASS,
  region: SUPPORTED_REGION
) => {
  const REACT_APP_BANKSTATEMENTS_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_REFERRAL`] || '';
  const REACT_APP_BANKSTATEMENTS_COMMERCIAL_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_COMMERCIAL_REFERRAL`] || '';
  const REACT_APP_BANKSTATEMENTS_SPECIFIC_ACCOUNT_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_SPECIFIC_ACCOUNT_REFERRAL`] || '';

  switch (dynamicDocActionType) {
    case DynamicDocumentActionType.TRIGGER_BANKSTATEMENTS:
      return REACT_APP_BANKSTATEMENTS_REFERRAL;
    case DynamicDocumentActionType.TRIGGER_BANKSTATEMENTS_SIX_MONTHS:
      return REACT_APP_BANKSTATEMENTS_COMMERCIAL_REFERRAL;
    case DynamicDocumentActionType.BANK_STATEMENTS_SPECIFIC_ACCOUNT:
      return REACT_APP_BANKSTATEMENTS_SPECIFIC_ACCOUNT_REFERRAL;
    default:
      return loanClass === LOAN_CLASS.COMMERCIAL ? REACT_APP_BANKSTATEMENTS_COMMERCIAL_REFERRAL : REACT_APP_BANKSTATEMENTS_REFERRAL;
  }
};

export const getBankStatementReferralCodeByIllionReferralType = (illionReferralType: DIGITAL_BANK_STATEMENT_REFERRAL_TYPE, region: SUPPORTED_REGION) => {
  const REACT_APP_BANKSTATEMENTS_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_REFERRAL`] || '';
  const REACT_APP_BANKSTATEMENTS_COMMERCIAL_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_COMMERCIAL_REFERRAL`] || '';
  const REACT_APP_BANKSTATEMENTS_SPECIFIC_ACCOUNT_REFERRAL = process.env[`REACT_APP_BANKSTATEMENTS_${region}_SPECIFIC_ACCOUNT_REFERRAL`] || '';

  switch (illionReferralType) {
    case DIGITAL_BANK_STATEMENT_REFERRAL_TYPE.CONSUMER:
      return REACT_APP_BANKSTATEMENTS_REFERRAL;
    case DIGITAL_BANK_STATEMENT_REFERRAL_TYPE.COMMERCIAL:
      return REACT_APP_BANKSTATEMENTS_COMMERCIAL_REFERRAL;
    case DIGITAL_BANK_STATEMENT_REFERRAL_TYPE.SPECIFIC_ACCOUNT:
      return REACT_APP_BANKSTATEMENTS_SPECIFIC_ACCOUNT_REFERRAL;
    default:
      return REACT_APP_BANKSTATEMENTS_REFERRAL;
  }
};
