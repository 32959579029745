import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Hidden, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { HTTP_STATUS_CODE, SUPPORTED_REGION } from '@driva-development/driva-types';

import { dashMutate } from '../../api';
import WelcomeShape from '../../assets/img/welcomeShape.png';
import { ContainerBox } from '../../components/ContainerBox';
import FormNumberField from '../../components/FormNumberField/FormNumberField';
import TrustBoxCarousel from '../../components/Trustbox/TrustBoxCarousel';
import { SESSION_RETURN_URL } from '../../config/const';
import { SessionManagerContext } from '../../context/SessionManagerContext';
import LoginLayout from '../../layouts/LoginLayout';
import { REGION_COOKIE } from '../../shared/constants';
import { LoginRequest, LoginResponse } from '../../types/types';
import { getReferral } from '../../utils';
import { currentRegion } from '../../utils/currentRegion';
import { DUPLICATE_LOAN_META_VALUE, isDuplicateApplication } from '../../utils/isDuplicationApplication';

import ExistingQuoteModal from './modals/ExistingQuoteModal/ExistingQuoteModal';
import NewQuoteModal from './modals/NewQuoteModal/NewQuoteModal';
import RegionSelectModal from './modals/RegionSelectModal/RegionSelectModal';

const PREFIX = 'LoginForm';
const loginFormClasses = {
  header: `${PREFIX}-header`,
  smallHeader: `${PREFIX}-smallHeader`,
  smallHeaderLeft: `${PREFIX}-smallHeaderLeft`,
  headerLeft: `${PREFIX}-headerLeft`,
  headerRight: `${PREFIX}-headerRight`,
  headerLogo: `${PREFIX}-headerLogo`,
  smsCodeButton: `${PREFIX}-smsCodeButton`,
  loginCodeInput: `${PREFIX}-loginCodeInput`,
  loginButton: `${PREFIX}-loginButton`,
  inputSection: `${PREFIX}-inputSection`,
  containerBoxWrap: `${PREFIX}-containerBoxWrap`,
  mainContainer: `${PREFIX}-mainContainer`,
  containerOffset: `${PREFIX}-containerOffset`,
  trustBoxSection: `${PREFIX}-trustBoxSection`,
  errorMessage: `${PREFIX}-errorMessage`,
  textPrompt: `${PREFIX}-textPrompt`,
  textPromptLink: `${PREFIX}-textPromptLink`,
  successMessage: `${PREFIX}-successMessage`,
  progress: `${PREFIX}-progress`,
};

const StyledLoginForm = styled(Form)(({ theme }) => ({
  [`& .${loginFormClasses.header}`]: {
    height: '280px',
    width: '75vw',
    margin: '-30px auto 60px',
    display: 'flex',
    padding: '0 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 775,
    [theme.breakpoints.down('md')]: {
      height: 160,
      width: '100vw',
      marginTop: 100,
    },
  },

  [`& .${loginFormClasses.smallHeader}`]: {
    margin: '0 auto',
    height: 160,
    width: '100vw',
    marginTop: 100,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
      marginBottom: 30,
    },
  },

  [`& .${loginFormClasses.smallHeaderLeft}`]: {
    fontFamily: 'ivyjournal,sans-serif',
    fontSize: 28,
    width: '90vw',
    zIndex: 9999999,
    marginTop: '-100px',

    '& p': {
      fontSize: 22,
      maxWidth: '500px',
      margin: '0 auto',
      width: '90vw',
      alignSelf: 'flex-start',
      [theme.breakpoints.down('lg')]: {
        width: '90vw',
        maxWidth: '750px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '750px',
      },
    },
  },

  [`& .${loginFormClasses.headerLeft}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontFamily: 'ivyjournal,sans-serif',
    fontSize: 40,
    maxWidth: 440,

    [theme.breakpoints.down('md')]: {
      fontFamily: 'avenir',

      width: '100vw',
      zIndex: 9999999,

      '& p': {
        fontWeight: 'bold',
        fontSize: 28,
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      width: '100%',
      fontWeight: 'normal',
    },

    '& span': {
      fontSize: 16,
      color: 'rgba(62,63,66,.8)',
      marginTop: 30,
    },
  },

  [`& .${loginFormClasses.headerRight}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${loginFormClasses.headerLogo}`]: {
    height: 153,
    width: 178,
  },

  [`& .${loginFormClasses.smsCodeButton}`]: {
    width: '100%',
    fontSize: 15,
    fontWeight: 600,
    boxShadow: 'none',
    padding: '14px 0 11px',
    '&:active': {
      boxShadow: 'none',
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      backgroundColor: '#fffacc',
    },
  },

  [`& .${loginFormClasses.loginCodeInput}`]: {
    width: '100%',
  },

  [`& .${loginFormClasses.loginButton}`]: {
    width: '100%',
    height: 44,
    fontSize: 15,
    fontWeight: 600,
    padding: '3px 0 0 0',
    '&:focus': {
      backgroundColor: '#FCF39C',
    },
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#fffbd9',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },

  [`& .${loginFormClasses.inputSection}`]: {
    padding: '30px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    alignItems: 'left',
    '& input': {
      border: '1px solid #DEE0E0',
      borderRadius: '5px !important',
    },
  },

  [`& .${loginFormClasses.containerBoxWrap}`]: {
    maxWidth: 775,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      maxWidth: '100%',
    },
  },

  [`& .${loginFormClasses.mainContainer}`]: {
    background: '#FBFBFD',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '50px',
    height: 'calc(100vh - 401px)',

    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      paddingBottom: '60px',
      height: 'calc(100vh - 240px)',
    },
  },

  [`& .${loginFormClasses.containerOffset}`]: {
    marginTop: '-100px',
    width: '75vw',
    maxWidth: '1150px',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },

  [`& .${loginFormClasses.trustBoxSection}`]: {
    display: 'flex',
    margin: '90px auto 20px auto',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
  },

  [`& .${loginFormClasses.errorMessage}`]: {
    color: '#cd2d2d',
    fontSize: '16px',
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  [`& .${loginFormClasses.textPrompt}`]: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  [`& .${loginFormClasses.textPromptLink}`]: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'black',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    display: 'inline-block',
    textAlign: 'left',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    '&:visited': {
      color: 'black',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${loginFormClasses.successMessage}`]: {
    fontSize: '16px',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },

  [`& .${loginFormClasses.progress}`]: {
    margin: '0 10px',
  },
}));

const validationSchemaAU = Yup.object().shape({
  mobile: Yup.string()
    .length(10, 'Enter your 10-digit mobile number starting with 04-')
    .matches(/^\({0,1}((0)(4)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/, 'Enter a valid Australian mobile number')
    .required('Enter your mobile number'),
  smsCode: Yup.string().length(6, 'Enter the 6-digit login code'),
});

const validationSchemaNZ = Yup.object().shape({
  mobile: Yup.string()
    .matches(/^02\d{7,9}$/, 'Enter a valid New Zealand mobile number')
    .required('Enter your mobile number'),
  smsCode: Yup.string().length(6, 'Enter the 6-digit login code'),
});

const SMS_LOGIN_CODE_SECONDS = 60;

const EMAIL_LOGIN_CODE_SECONDS = 40;

// Signals we are in testing (wont work in production)
const IS_AUTO_LOGIN = process.env.REACT_APP_AUTO_LOGIN?.toString() === 'true';

// eslint-disable-next-line complexity
const Login: FC = () => {
  const midMatches = useMediaQuery('(max-width:550px)');
  const { sessionLoanUuid, setSessionLoanUuid } = useContext(SessionManagerContext);

  const [searchParams] = useSearchParams();
  const redirectReason = searchParams.get('reason');
  const loginLoanUuid = searchParams.get('uuid') || searchParams.get('loanUuid');

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate: currentUserMutate } = dashMutate<{ loanUuid: string }, unknown, {}>('currentUser');

  const {
    mutate: generateSmsCode,
    isLoading: isSmsCodeLoading,
    isSuccess: isSmsCodeSuccess,
    isError: isSmsCodeError,
    reset: resetSendSmsCode,
  } = dashMutate<{}, {}, { mobile: string }>('generateSmsCode');

  const {
    mutate: generateEmailAuth,
    status: emailCodeStatus,
    isSuccess: isEmailCodeSuccess,
    isError: isEmailCodeError,
  } = dashMutate<{}, { message: string }, { mobile: string; loanUuid?: string }>('generateEmailAuth');

  const {
    mutate: login,
    data: loginData,
    status: loginStatus,
    error: loginError,
    isLoading: isLoginLoading,
    isSuccess: isLoginSuccess,
    isError: isLoginError,
    reset: resetLogin,
  } = dashMutate<LoginResponse, Error, LoginRequest>('login');

  const loginCodeInputRef = useRef<HTMLInputElement>(null);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showExistingQuoteModal, setShowExistingQuoteModal] = useState(false);

  const [isMobileInputDisabled, setIsMobileInputDisabled] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const isEmailCodeRequested = emailCodeStatus !== 'idle';
  const isEmailCodeResponse = isEmailCodeSuccess || isEmailCodeError;
  const isLoginRequested = loginStatus !== 'idle';

  const isLoginUnauthorized = (loginError?.cause as any)?.status === HTTP_STATUS_CODE.UNAUTHORIZED;
  const isLoginServerError = isLoginError && !isLoginUnauthorized;

  const isSmsCodeButtonVisible = !isSmsCodeError && !isEmailCodeResponse && !isLoginServerError;
  const isSmsCodeButtonDisabled = isSmsCodeLoading || isLoginLoading || seconds > 0;
  const smsCodeButtonText = isLoginLoading || isLoginSuccess ? 'Please wait...' : seconds > 0 ? `Resend in ${seconds} seconds` : 'Send SMS verification code';

  const isSmsCodeMessageVisible = isSmsCodeSuccess && seconds > EMAIL_LOGIN_CODE_SECONDS && !isLoginServerError;
  const isEmailCodeLinkVisible = isSmsCodeSuccess && seconds <= EMAIL_LOGIN_CODE_SECONDS && !isLoginServerError;

  const isLoginVisible = isSmsCodeSuccess || isEmailCodeSuccess || isLoginRequested;
  const isLoginDisabled = isLoginRequested && !isLoginUnauthorized;
  const loginCodeInputPlaceholderText = `Enter the login code from your ${isEmailCodeSuccess ? 'email' : 'SMS'}`;
  const [showRegionSelectModal, setShowRegionSelectModal] = useState(false);
  const [cookies, setCookie] = useCookies([REGION_COOKIE]);

  useEffect(() => {
    if (redirectReason && redirectReason === DUPLICATE_LOAN_META_VALUE) {
      setShowExistingQuoteModal(true);
    }
  }, [redirectReason]);

  useEffect(() => {
    if (sessionLoanUuid == null) {
      return;
    }

    const timeout = setTimeout(() => {
      currentUserMutate(
        {},
        {
          onSuccess: (data) => {
            isDuplicateApplication(data.loanUuid)
              .then((isDuplicate) => {
                if (!isDuplicate) {
                  navigate({ pathname: '/portal' });
                }
              })
              .catch((e) => console.warn(e));
          },
        }
      );
    }, 1);

    // DevNote: This is a hack to prevent the request from being called twice in React Development Strict Mode
    return () => clearTimeout(timeout);
  }, [currentUserMutate, navigate, sessionLoanUuid, setSessionLoanUuid]);

  useEffect(() => {
    if (!isSmsCodeSuccess) {
      return;
    }

    const timeout = setTimeout(() => {
      if (seconds === 1) {
        setIsMobileInputDisabled(false);
        resetSendSmsCode();
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isSmsCodeSuccess, seconds, resetSendSmsCode, resetLogin]);

  useEffect(() => {
    if (isSmsCodeSuccess || isEmailCodeSuccess) {
      setTimeout(() => loginCodeInputRef.current?.focus(), 0);
    }
  }, [isSmsCodeSuccess, isEmailCodeSuccess]);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
    const region = currentRegion();
    if (region === SUPPORTED_REGION.AU && (timezone.includes('Pacific/Auckland') || timezone.includes('Antarctica/McMurdo')) && cookies.region !== 'selected') {
      setShowRegionSelectModal(true);
    }
    if (region === SUPPORTED_REGION.NZ && timezone.includes('Australia') && cookies.region !== 'selected') {
      setShowRegionSelectModal(true);
    }
  }, [cookies.region]);

  const handleSendSms = (values: { mobile: string; smsCode: string }) => {
    setIsMobileInputDisabled(true);
    setTimeout(() => loginCodeInputRef.current?.focus(), 0);

    generateSmsCode(
      { mobile: values.mobile },
      {
        onSuccess: () => {
          setSeconds(SMS_LOGIN_CODE_SECONDS);
        },
        onError: (error) => {
          console.warn('Error sending SMS login code', { mobile: values.mobile, error });
        },
        onSettled: () => {
          resetLogin();
        },
      }
    );
  };

  const handleSendEmail = (values: { mobile: string }, loanUuid: string | null) => {
    loginCodeInputRef.current?.focus();
    setTimeout(() => loginCodeInputRef.current?.focus(), 0);

    generateEmailAuth(
      {
        mobile: values.mobile,
        loanUuid: loanUuid ?? undefined,
      },
      {
        onSuccess: () => {},
        onError: (error) => {
          console.warn('Error sending email login code', { mobile: values.mobile, error });
        },
        onSettled: () => {
          resetSendSmsCode();
          resetLogin();
        },
      }
    );
  };

  const handleSubmit = ({ mobile, smsCode }: { mobile: string; smsCode: string }) => {
    if (!isLoginVisible) {
      handleSendSms({ mobile, smsCode: '' });
    } else {
      login(
        { mobile, code: smsCode, loanUuid: loginLoanUuid, testing: IS_AUTO_LOGIN },
        {
          onSuccess: async ({ loanUuid, isAccessPortal, isLoanExpired, isDeclined, isInstantQuote }: LoginResponse) => {
            if (isAccessPortal === true) {
              setSessionLoanUuid(loanUuid);
              const loginPathname = sessionStorage.getItem(SESSION_RETURN_URL) ?? '/portal';
              navigate({ pathname: loginPathname });
              return;
            }

            if (isLoanExpired === true) {
              setShowUpdateModal(true);
              return;
            }

            if (isDeclined === true) {
              navigate((await getReferral(loanUuid, undefined, 0)).declinePath);
              return;
            }

            if (isInstantQuote === true) {
              navigate({ pathname: `/quote/${loanUuid}` });
              return;
            }

            navigate({ pathname: `/unsuccessful/${loanUuid}` });
          },
          onError: (error) => {
            console.warn('Error logging in', { mobile, smsCode, error });
          },
        }
      );
    }
  };

  const handleModalClose = () => {
    setShowUpdateModal(false);
    navigate({
      pathname: '/welcome',
    });
  };

  const handleRegionModalClose = () => {
    setShowRegionSelectModal(false);
    setCookie(REGION_COOKIE, 'selected');
  };

  const initialValues = {
    mobile: '',
    smsCode: '',
  };

  const region = currentRegion();
  let validationSchema;
  switch (region) {
    case SUPPORTED_REGION.NZ:
      validationSchema = validationSchemaNZ;
      break;
    case SUPPORTED_REGION.AU:
    default:
      validationSchema = validationSchemaAU;
      break;
  }

  return (
    <LoginLayout>
      <RegionSelectModal closeModal={handleRegionModalClose} showModal={showRegionSelectModal} setCookie={setCookie} region={region} />
      <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnBlur={true} validateOnChange={true} onSubmit={handleSubmit}>
        {({ values, errors, setFieldValue, setFieldTouched }) => {
          const isMobileNumberInvalid = errors.mobile != null || values.mobile.length === 0;
          const isLoginCodeInvalid = errors.smsCode != null || values.smsCode.length === 0;

          return (
            <StyledLoginForm>
              {!midMatches && (
                <Hidden only={['xs', 'sm']}>
                  <Grid container={true} className={loginFormClasses.header}>
                    <Grid item={true} xs={12} md={7} className={loginFormClasses.headerLeft}>
                      <p>Login to your portal</p>
                    </Grid>
                    <Grid item={true} md={5} className={loginFormClasses.headerRight}>
                      <img src={WelcomeShape} className={loginFormClasses.headerLogo} alt="" />
                    </Grid>
                  </Grid>
                </Hidden>
              )}
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid container={true} className={loginFormClasses.smallHeader}>
                  <Grid item={true} xs={12} className={loginFormClasses.smallHeaderLeft}>
                    <p>Login to your portal</p>
                  </Grid>
                </Grid>
              </Hidden>
              <div className={loginFormClasses.mainContainer}>
                <div className={loginFormClasses.containerOffset}>
                  <Grid container spacing={3}>
                    <Grid item={true} xs={12} className={loginFormClasses.containerBoxWrap}>
                      <ContainerBox title="Enter the mobile number used in your application" dashboard={true}>
                        <div className={loginFormClasses.inputSection}>
                          <FormNumberField
                            name="mobile"
                            placeholder={t('login.mobilePlaceholder')}
                            inputProps={{
                              maxLength: 11,
                              'data-cy': 'mobile-input',
                            }}
                            removeFormat={true}
                            submitFailed={false}
                            disabled={isMobileInputDisabled}
                          />

                          {isSmsCodeButtonVisible ? (
                            <Button
                              className={loginFormClasses.smsCodeButton}
                              variant="contained"
                              onClick={() => {
                                handleSendSms(values);
                                setFieldTouched('smsCode', false);
                                setFieldValue('smsCode', '');
                              }}
                              data-cy="send-sms"
                              disabled={isSmsCodeButtonDisabled || isMobileNumberInvalid}
                            >
                              {smsCodeButtonText}
                            </Button>
                          ) : null}

                          {isSmsCodeMessageVisible ? (
                            <p className={loginFormClasses.successMessage} data-cy="smsTextPrompt">
                              A verification code has been sent via SMS to your mobile.
                            </p>
                          ) : null}

                          {isEmailCodeLinkVisible && (
                            <div className={loginFormClasses.textPrompt}>
                              <p>Didn't receive your verification code?</p>
                              <p>
                                <Button
                                  onClick={() => {
                                    handleSendEmail(values, loginLoanUuid);
                                    setFieldTouched('smsCode', false);
                                    setFieldValue('smsCode', '');
                                  }}
                                  className={loginFormClasses.textPromptLink}
                                  data-cy="send-email-code"
                                  disabled={isEmailCodeRequested}
                                >
                                  Send a new verification code to your email address.
                                </Button>
                              </p>
                            </div>
                          )}

                          {isSmsCodeError ? (
                            <div className={loginFormClasses.textPrompt} data-cy="smsErrorMessage">
                              <p style={{ marginBottom: 10 }}>We encountered a problem sending the verification code to your mobile.</p>
                              <Button
                                className={loginFormClasses.smsCodeButton}
                                variant="contained"
                                onClick={() => {
                                  handleSendEmail(values, loginLoanUuid);
                                }}
                                data-cy="send-email-code"
                                disabled={isEmailCodeRequested}
                              >
                                Send verification code to your email
                              </Button>
                            </div>
                          ) : null}

                          {isEmailCodeSuccess ? (
                            <div className={loginFormClasses.successMessage} data-cy="emailTextPrompt">
                              <p>A verification code has been sent to your registered email address.</p>
                              <p>Enter the verification code found in your email.</p>
                            </div>
                          ) : null}

                          {isEmailCodeError ? (
                            <div className={loginFormClasses.errorMessage} data-cy="emailCodeError">
                              <p>We encountered a problem sending the verification code to your email address.</p>
                              <p>Please contact us on {t('phone')} for support.</p>
                            </div>
                          ) : null}

                          {isLoginVisible ? (
                            <>
                              <div className={loginFormClasses.loginCodeInput}>
                                <FormNumberField
                                  ref={loginCodeInputRef}
                                  name="smsCode"
                                  title=""
                                  placeholder={loginCodeInputPlaceholderText}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 10,
                                    'data-cy': 'login-code-input',
                                  }}
                                  backgroundColor="#FFFFFF"
                                  removeFormat={true}
                                  disabled={isLoginDisabled}
                                />
                              </div>
                              <Button
                                variant="contained"
                                className={loginFormClasses.loginButton}
                                type="submit"
                                fullWidth
                                disabled={isLoginCodeInvalid || isLoginDisabled}
                              >
                                Login
                              </Button>
                            </>
                          ) : null}

                          {isLoginUnauthorized ? (
                            <div className={loginFormClasses.errorMessage} data-cy="loginAuthErrorMessage">
                              <p>{loginError?.message}</p>
                            </div>
                          ) : null}

                          {isLoginServerError ? (
                            <div className={loginFormClasses.errorMessage} data-cy="loginServerErrorMessage">
                              <p>We encountered a problem while processing your login.</p>
                              <p>Please contact us on {t('phone')} for support.</p>
                            </div>
                          ) : null}

                          <div className={loginFormClasses.textPrompt}>
                            Don't have an account yet?{' '}
                            <a href={process.env.REACT_APP_DOMAIN} className={loginFormClasses.textPromptLink}>
                              Get started with a free quote
                            </a>
                          </div>
                        </div>

                        <NewQuoteModal closeModal={handleModalClose} showModal={showUpdateModal} userName={loginData?.firstName ?? ''} />
                        <ExistingQuoteModal closeModal={() => setShowExistingQuoteModal(false)} showModal={showExistingQuoteModal} />
                      </ContainerBox>
                      <div className={loginFormClasses.trustBoxSection}>
                        <TrustBoxCarousel />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </StyledLoginForm>
          );
        }}
      </Formik>
    </LoginLayout>
  );
};

export default Login;
