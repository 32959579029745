import { colors } from '@mui/material';

import { ThemeComponents } from './types';

export const MuiButton: ThemeComponents['MuiButton'] = {
  defaultProps: {
    variant: 'default' as any,
  },
  variants: [
    {
      props: { variant: 'default' as any },
      style: {
        backgroundColor: 'transparent',
      },
    },
  ],
  styleOverrides: {
    root: {
      fontFamily: 'Avenir',
      borderRadius: '30px',
      color: '#1C242A',
      fontSize: '15px',
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'none',
    },
    contained: {
      backgroundColor: '#FCF39C',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#FCEB45',
        boxShadow: 'none',
      },
      '&:disabled': {
        backgroundColor: '#FCEB45',
      },
    },
    containedSizeSmall: {
      padding: '5px 25px 1px',
      fontSize: '12px',
      fontWeight: '600',
    },
    containedPrimary: {
      backgroundColor: '#FCF39C',
      color: '#1C242A',
      '&:hover': {
        backgroundColor: '#FCEB45',
        color: '#1C242A',
      },
    },
    textPrimary: {
      backgroundColor: '#A1E2C1',
      border: '1px #1C242A solid',
      '&:hover': {
        backgroundColor: colors.grey[300],
      },
    },
    textSecondary: {
      color: '#1C242A',
      border: '2px #1C242A solid',
      padding: '15px 35px',
      '&:hover': {
        backgroundColor: '#1C242A',
        color: '#fff',
      },
    },
    startIcon: {},
  },
};
