import React, { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useModal } from '../../context/ModalContext';
import { classNames } from '../../utils';

import styles from './modalContainer.module.scss';

const ANIMATION_DURATION = 300;

interface ModalContainerProps {
  children: ReactNode;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children }) => {
  const { isBottomSheet, closeModal } = useModal();

  const [modal, setModal] = useState<ReactNode>(children);
  const [slide, setSlide] = useState<string | null>(styles.slideUp);

  useEffect(() => {
    if (children == null && modal != null) {
      setSlide(styles.slideDown);
      setTimeout(() => setModal(null), ANIMATION_DURATION);
    }

    if (children != null && modal == null) {
      setSlide(styles.slideUp);
      setModal(children);
    }
  }, [modal, children]);

  const handleOverlayClick: MouseEventHandler = () => {
    closeModal();
  };

  const handleModalWrapperClick: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const handleEscape = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    if (modal != null) {
      document.addEventListener('keydown', handleEscape, false);

      return () => {
        document.removeEventListener('keydown', handleEscape, false);
      };
    }
  }, [modal, handleEscape]);

  useLayoutEffect(() => {
    if (modal != null) {
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#258460');

      return () => {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#3cdd9f');
      };
    }
  }, [modal]);

  return modal != null ? (
    <div className={styles.overlayContainer} onClick={handleOverlayClick}>
      <div className={classNames(styles.modalContainer, slide, isBottomSheet ? styles.bottomSheet : null)}>
        <div onClick={handleModalWrapperClick}>{modal}</div>
      </div>
    </div>
  ) : null;
};
