import React, { forwardRef } from 'react';
import { useField } from 'formik';
import { Grid, InputAdornment, TextField } from '@mui/material';

import FormTitle from '../FormTitle';

import { NumberFormatCustomWithoutThousandSeparator, NumberFormatCustomWithThousandSeparator } from './NumberFormatCustom';

import classes from './formNumberField.module.scss';

interface INumberField {
  name: string;
  value?: string;
  title?: string;
  prefix?: string;
  placeholder?: string;
  fullWidth?: boolean;
  tipTitle?: string;
  variant?: any;
  backgroundColor?: string;
  inputProps?: any;
  removeFormat?: boolean | string | undefined;
  withDollarSign?: boolean;
  shrinkTitle?: boolean;
  submitFailed?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormNumberField = forwardRef<HTMLInputElement, INumberField>((props, ref) => {
  const [{ value, onChange: onFieldChange, onBlur }, meta] = useField(props);

  const { title, autoFocus, tipTitle, prefix, inputProps, removeFormat, withDollarSign, shrinkTitle, submitFailed, name, disabled, placeholder, onChange } =
    props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event);

    if (onChange) {
      onChange(event);
    }
  };

  let formatInput = {};
  if (!removeFormat) {
    formatInput = {
      startAdornment: prefix && (
        <InputAdornment position="start" className={classes.adornment}>
          $
        </InputAdornment>
      ),
      inputComponent: NumberFormatCustomWithThousandSeparator,
    };
  } else {
    formatInput = {
      inputComponent: NumberFormatCustomWithoutThousandSeparator,
    };
  }

  if (withDollarSign) {
    formatInput = {
      startAdornment: (
        <InputAdornment position="start" className={classes.adornment}>
          $
        </InputAdornment>
      ),
      inputComponent: NumberFormatCustomWithThousandSeparator,
    };
  }

  return (
    <>
      <Grid container={true} direction="column">
        {(title || tipTitle) && (
          <Grid item={true}>
            <FormTitle title={title} tipTitle={tipTitle} shrinkTitle={shrinkTitle ? true : false} />
          </Grid>
        )}
        <Grid item={true}>
          <TextField
            inputRef={ref}
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            error={meta.touched && meta.error ? true : false}
            InputProps={{
              ...inputProps,
              ...formatInput,
              inputMode: 'numeric',
            }}
            inputProps={{ ...inputProps }}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            title={title}
            name={name}
            type="tel"
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
          {(submitFailed || meta.touched) && meta.error ? (
            <div className="inputError" data-cy={`input-error-${name}`}>
              {meta.error}
            </div>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
});

FormNumberField.displayName = 'FormNumberField';

export default FormNumberField;
