import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { v4 } from 'uuid';

import { LD_ANONYMOUS_USER_STORAGE_KEY, LD_CONTEXT_KIND } from './constants';

export const getDeviceContext = (): LDSingleKindContext => {
  const ldAnonymousUserKey = sessionStorage.getItem(LD_ANONYMOUS_USER_STORAGE_KEY);
  const ldUserKey = ldAnonymousUserKey ?? v4();

  if (ldAnonymousUserKey == null) {
    sessionStorage.setItem(LD_ANONYMOUS_USER_STORAGE_KEY, ldUserKey);
  }

  return {
    kind: LD_CONTEXT_KIND.DEVICE,
    key: ldUserKey,
    anonymous: true,
  };
};
