// match current TLD to envvars
export function matchTld(host: string): string {
  const current = new URL(window.location.href);
  const currentTld = current.hostname.split('.').slice(1).join('.');
  if (currentTld === '') {
    return host;
  }

  const requested = new URL(host);
  const requestedTld = requested.hostname.split('.').slice(1).join('.');

  return host.replace(requestedTld, currentTld);
}
