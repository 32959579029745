import React, { createContext, ReactNode, Reducer, useCallback, useContext, useReducer } from 'react';

import { applicationStepToUrl, LOAN_APPLICATION_STATUS } from '@driva-development/driva-types';

import { LD_CONTEXT_KIND } from '../lib/launchDarkly/constants';
import { LoanApplication } from '../types/types';
import { currentRegion } from '../utils/currentRegion';
import { ApplicationFormData } from '../views/Portal/types';

import { useAnalytics } from './AnalyticsContext';

type StateReducer<S> = Reducer<S, Partial<S>>;

const stateReducer = <S,>(state: S, action: Partial<S>): S => ({ ...state, ...action });

interface SessionContextProps {
  application: any;
  setApplication: (application: Partial<LoanApplication>) => void;
  formsData: ApplicationFormData;
  setFormsData: (formsData: ApplicationFormData) => void;
  applicationStatus?: LOAN_APPLICATION_STATUS;
}

export const SessionContext = createContext<SessionContextProps>({
  application: {} as any,
  setApplication: () => {},
  formsData: { region: currentRegion() } as any,
  setFormsData: () => {},
});

SessionContext.displayName = 'SessionContext';

interface SessionProviderProps {
  application: LoanApplication;
  formsData: ApplicationFormData;
  children?: ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({ application, formsData, children }) => {
  const [applicationState, setApplicationState] = useReducer<StateReducer<LoanApplication>>(stateReducer, application ?? {});
  const [formsDataState, setFormsDataState] = useReducer<StateReducer<ApplicationFormData>>(stateReducer, formsData ?? {});

  const { getContext, setContext } = useAnalytics();

  const setApplicationCallback = useCallback<(application: Partial<LoanApplication>) => void>(
    (application) => setApplicationState(application),
    [setApplicationState]
  );

  const setFormsDataCallback = useCallback<(formsData: ApplicationFormData) => void>(
    (formsData) => {
      setFormsDataState(formsData);
      setContext({ ...getContext(), kind: LD_CONTEXT_KIND.USER, portalStep: applicationStepToUrl[formsData.step] });
    },
    [setFormsDataState, getContext, setContext]
  );

  return (
    <SessionContext.Provider
      value={{
        application: applicationState,
        setApplication: setApplicationCallback,
        formsData: formsDataState,
        setFormsData: setFormsDataCallback,
        applicationStatus: applicationState?.customerLoanApplication.status,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = (): SessionContextProps => useContext(SessionContext);
