import React, { CSSProperties, FC } from 'react';

interface LoadingSpinnerProps {
  styles?: CSSProperties;
}
export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ styles }) => {
  const spinnerStyles = {
    margin: 'auto',
    background: 'none',
    display: 'block',
    height: '24px',
    width: '24px',
    ...styles,
  };

  return (
    <svg style={spinnerStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="rotate(0 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.875s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(45 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.625s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(135 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.375s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.125s" repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(315 50 50)">
        <rect x="45.5" y="16.5" rx="4.18" ry="4.18" width="9" height="19" fill="#ffffff">
          <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
        </rect>
      </g>
    </svg>
  );
};
