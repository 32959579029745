import { ThemeComponents } from './types';

export const MuiAppBar: ThemeComponents['MuiAppBar'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
    positionFixed: {
      borderBottom: '1px solid #EAEDF3',
    },
    colorPrimary: {
      backgroundColor: '#fff',
    },
  },
};
