import { LOAN_APPLICATION_STATUS } from '@driva-development/driva-types';

import { axiosRetry } from './axiosRetry';
import { matchTld } from './matchTld';

const MAX_RETRIES = 2;
const RETRY_DELAY = 3000;

export const DUPLICATE_LOAN_META_VALUE = 'duplicateLoanApplication';
export const DUPLICATE_LOGIN_PATH = `/login?reason=${DUPLICATE_LOAN_META_VALUE}`;

interface ApplicationStatusResponse {
  loanUuid: string;
  status: LOAN_APPLICATION_STATUS;
  statusText: string;
  reasons?: string[];
}

export const isDuplicateApplication = async (loanUuid: string, maxRetries: number = MAX_RETRIES): Promise<boolean> => {
  const response = await axiosRetry<{ data: ApplicationStatusResponse }>(
    { method: 'GET', baseURL: matchTld(process.env.REACT_APP_SERVER_BASE_URL ?? ''), url: `/api/v2/get-application-status/${loanUuid}` },
    {
      shouldRetry: (data) => !data?.data.status,
      maxRetries,
      retryDelay: RETRY_DELAY,
      retryOnError: true,
    }
  ).catch((error) => {
    console.error('Error retrieving application status: ', error);
    return undefined;
  });

  if (response == null) {
    return false;
  }

  const {
    data: {
      data: { reasons, status },
    },
  } = response;

  return status === LOAN_APPLICATION_STATUS.CLOSED && reasons?.length && reasons?.includes(DUPLICATE_LOAN_META_VALUE) ? true : false;
};
