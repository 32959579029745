import { Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'RedirectModal';

export const redirectModalClasses = {
  paper: `${PREFIX}-paper`,
  modal: `${PREFIX}-modal`,
  modalHeader: `${PREFIX}-modalHeader`,
  modalWrapper: `${PREFIX}-modalWrapper`,
  modalWrapperBreakdown: `${PREFIX}-modalWrapperBreakdown`,
  modalWrapperEdit: `${PREFIX}-modalWrapperEdit`,
  modalHeading: `${PREFIX}-modalHeading`,
  modalHeadingPartnerPage: `${PREFIX}-modalHeadingPartnerPage`,
  modalStyle: `${PREFIX}-modalStyle`,
  modalDescription: `${PREFIX}-modalDescription`,
  modalTop: `${PREFIX}-modalTop`,
  modalTopPartnerPage: `${PREFIX}-modalTopPartnerPage`,
  modalContent: `${PREFIX}-modalContent`,
  descriptionLight: `${PREFIX}-descriptionLight`,
  Button: `${PREFIX}-Button`,
  goBack: `${PREFIX}-goBack`,
  partnerTop: `${PREFIX}-partnerTop`,
  backDrop: `${PREFIX}-backDrop`,
};

export const StyledRedirectModal = styled(Modal)(({ theme }) => ({
  overflow: 'auto',
  display: 'flex',

  ['& .MuiBackdrop-root']: {
    zIndex: -1,
    outline: 'none',
  },

  [`& .${redirectModalClasses.paper}`]: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  [`& .${redirectModalClasses.modalHeader}`]: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h5': {
      fontSize: '19px',
      fontFamily: 'avenir',
      fontWeight: 'bold',
    },
  },

  [`& .${redirectModalClasses.modalWrapper}`]: {
    display: 'flex',
    border: 'none',
    outline: 'none',
    position: 'relative',
    padding: '30px 10px',
    width: '100%',
    margin: 'auto',
    '& svg': {
      cursor: 'pointer',
    },
  },

  [`& .${redirectModalClasses.modalWrapperBreakdown}`]: {
    '&>div': {
      width: '100%',
    },
  },

  [`& .${redirectModalClasses.modalWrapperEdit}`]: {
    justifyContent: 'center',

    '& h5': {
      fontSize: 19,
      fontFamily: 'avenir',
      fontWeight: 'bold',
    },

    '& .modalEditRoot': {
      width: '50vw',
      [theme.breakpoints.down('lg')]: {
        width: '60vw',
      },
      [theme.breakpoints.down('md')]: {
        padding: 30,
        width: '80vw',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '25px 20px',
        width: '100%',
      },

      '& .modalEditHeader': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    '& button': {
      marginTop: 15,
    },
  },

  [`& .${redirectModalClasses.modalHeading}`]: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    width: '70%',
    '& h1': {
      fontFamily: 'ivyjournal,sans-serif',
      fontWeight: 400,
      marginBottom: 15,
      fontSize: 30,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
  },

  [`& .${redirectModalClasses.modalHeadingPartnerPage}`]: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    width: 'inherit',
    '& h1': {
      fontFamily: 'ivyjournal,sans-serif',
      fontWeight: 400,
      marginBottom: 15,
      fontSize: 30,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: 26,
      },
    },
  },

  [`& .${redirectModalClasses.modalStyle}`]: {
    width: '40vw',
    padding: '50px 60px',
    maxWidth: 700,
    background: '#FBFBFD',
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      padding: 30,
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px 20px',
      width: '100%',
      top: 15,
    },
    margin: '0 auto',
    position: 'relative',
    zIndex: 11,
  },

  [`& .${redirectModalClasses.modalDescription}`]: {
    whiteSpace: 'pre-line',
  },

  [`& .${redirectModalClasses.modalTop}`]: {
    display: 'flex',
    alignItems: 'self-start',
    justifyContent: 'space-between',
    '& img': {
      width: 80,
    },
    '&.partnerLogoSupport': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
      },
    },
    position: 'relative',
  },

  [`& .${redirectModalClasses.modalTopPartnerPage}`]: {
    display: 'flex',
    alignItems: 'self-start',
    justifyContent: 'space-between',
    '& img': {
      width: 80,
    },
    '&.partnerLogoSupport': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      textAlign: 'center',
    },
    position: 'relative',
  },

  [`& .${redirectModalClasses.modalContent}`]: {
    '& p': {
      lineHeight: '25px',
      marginTop: 10,
      fontFamily: 'inherit',
      fontSize: 16,
      whiteSpace: 'pre-line',
      fontWeight: 700,
    },
  },

  [`& .${redirectModalClasses.descriptionLight}`]: {
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 15,
  },

  [`& .${redirectModalClasses.Button}`]: {
    height: 44,
    width: '100%',
    background: '#FFF39C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    color: '#000',
    fontSize: 15,
    fontFamily: 'Avenir',
    margin: '40px auto 20px',
    marginBottom: 0,
    '&:hover': {
      backgroundColor: '#FFF39C',
    },
  },

  [`& .${redirectModalClasses.goBack}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  [`& .${redirectModalClasses.partnerTop}`]: {
    position: 'absolute',
    top: '15px',
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    '& div': {
      marginTop: '15px',
    },
    '& span': {
      display: 'inline-block',
      width: '2px',
      height: '25px',
      background: '#e2e0e0',
      margin: '0 7px 0 5px',
    },
  },

  [`& .${redirectModalClasses.backDrop}`]: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));
