import React, { FC, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { DateTime } from 'luxon';

import { FieldContainer } from '../FieldContainer/FieldContainer';
import { FormFieldProps } from '../types';

import 'react-calendar/dist/Calendar.css';
import styles from './dateField.module.scss';

interface DateFieldProps extends FormFieldProps {
  maxDate?: Date;
}

export const DateField: FC<DateFieldProps> = ({ name, title, subtitle, description, placeholder, helperText, maxDate = new Date(), disabled = false }) => {
  const { clearErrors } = useFormContext();

  const ref = useRef<HTMLInputElement>(null);

  const currentValue = useWatch({ name });

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(DateTime.fromISO(currentValue).isValid ? DateTime.fromISO(currentValue).toFormat('dd/MM/yyyy') : '');

  useEffect(() => {
    setShowCalendar(false);
  }, [currentValue]);

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    setShowCalendar(false);

    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.blur();
      setShowCalendar(false);
    }
  };

  return (
    <FieldContainer name={name} title={title} subtitle={subtitle} description={description} helperText={helperText}>
      <Controller
        name={name}
        render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitting } }) => (
          <div className={styles.inputContainer}>
            <PatternFormat
              getInputRef={ref}
              value={displayValue}
              className={error ? styles.error : ''}
              placeholder={placeholder}
              format={'##/##/####'}
              inputMode={'numeric'}
              disabled={isSubmitting || disabled}
              onChange={(event) => {
                const parsedSelectedDate = DateTime.fromFormat(event?.target.value, 'dd/MM/yyyy');
                setDisplayValue(event.target.value);
                onChange(parsedSelectedDate.isValid ? parsedSelectedDate.toISODate() : undefined);
                clearErrors(name);
              }}
              onKeyDown={onKeyDown}
              onClick={() => setShowCalendar(true)}
              data-cy={`date-field-${name}`}
            />
            {showCalendar ? (
              <Calendar
                maxDate={maxDate}
                value={DateTime.fromISO(value).isValid ? DateTime.fromISO(value).toJSDate() : null}
                onChange={(selectedDate) => {
                  const parsedSelectedDate = DateTime.fromJSDate(selectedDate as Date);
                  setDisplayValue(parsedSelectedDate.toFormat('dd/MM/yyyy'));
                  onChange(parsedSelectedDate.toISODate());
                  clearErrors(name);
                }}
                className={styles.calendar}
                locale="en-AU"
              />
            ) : null}
          </div>
        )}
      />
    </FieldContainer>
  );
};
