import React, { FC } from 'react';

import helpCircle from '../../assets/img/info-circle.svg';
import { ToolTipBubble } from '../ToolTipBubble/ToolTipBubble';

import { ToolTipCardProps } from './types';

import styles from './toolTipCard.module.scss';

export const ToolTipCard: FC<ToolTipCardProps> = ({ label, content }) => {
  return (
    <ToolTipBubble content={content} direction="top">
      <div className={styles.toolTipCard}>
        <div className={styles.toolTipLabel}>
          <div className={styles.helpCircleContainer}>
            <img src={helpCircle} alt="i" />
          </div>
          {label}
        </div>
      </div>
    </ToolTipBubble>
  );
};
