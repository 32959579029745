import { palette } from '../palette';

import { ThemeComponents } from './types';

export const MuiInput: ThemeComponents['MuiInput'] = {
  styleOverrides: {
    root: {
      color: '#1C242A',
      '&$focused': {
        color: '#1C242A',
      },
      '&$error': {
        '& svg': {
          color: palette.error.main,
          width: '0.75em',
          height: '0.75em',
        },
      },
    },
    underline: {
      '&:before': {
        borderBottom: 'none',
        content: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&$error:after': {
        borderBottomColor: '#CD2D2D',
      },
    },
  },
};
