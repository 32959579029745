import { ThemeComponents } from './types';

export const MuiToolbar: ThemeComponents['MuiToolbar'] = {
  styleOverrides: {
    root: {
      justifyContent: 'space-between',
      padding: '0 24px !important',
      gutters: {
        paddingLeft: '360px',
        paddingRight: '16px',
      },
    },
  },
};
