import React, { FC, ReactNode, useContext } from 'react';
import { Box, Divider, styled, Typography } from '@mui/material';

import { WhiteLabelContext } from '../layouts/WhiteLabelLayout/context';

import { IWhiteLabelStyle } from './../layouts/WhiteLabelLayout/context';

const TitleContainerBox = styled(Box)<{ props: { whiteLabel?: IWhiteLabelStyle; isQuote?: boolean } }>(({ theme, props: { whiteLabel, isQuote } }) => ({
  border: whiteLabel?.containerBox ? whiteLabel.containerBox.border : '1px solid #EAEDF3',
  background: '#fff',
  borderRadius: 5,
  ...(isQuote ? { margin: '0 auto', maxWidth: '100%' } : { marginBottom: 30, marginTop: 20 }),
  [theme.breakpoints.down('md')]: {
    ...(isQuote ? { margin: '0 auto' } : { maxWidth: '100%' }),
    marginLeft: 0,
  },
  '& > .MuiGrid-item': {
    padding: 10,
  },
}));

const TitleBar = styled(Box)<{ props: { whiteLabel?: IWhiteLabelStyle } }>(({ theme, props: { whiteLabel } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: whiteLabel?.containerBox ? whiteLabel.containerBox.padding : '20px 20px 18px',
  gap: 10,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },

  '@media (max-width: 440px)': {
    padding: whiteLabel?.containerBox ? whiteLabel.containerBox.padding : '20px 10px 18px',
    alignItems: 'start',
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bolder',
  fontSize: '18px',
  lineHeight: '1.3em',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}));

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '1.3em',
  padding: '0 20px 14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    padding: '0px 20px 11px',
  },
  '@media (max-width: 440px)': {
    padding: '0px 10px 11px',
  },
}));

const MainContent = styled('div')<{ props: { whiteLabel?: IWhiteLabelStyle; dashboard?: boolean } }>(({ theme, props: { whiteLabel, dashboard } }) =>
  dashboard
    ? {
        padding: 0,
        marginTop: 0,
      }
    : {
        padding: whiteLabel?.containerBox ? whiteLabel.containerBox.padding : '5px 30px 40px',
        [theme.breakpoints.down('md')]: {
          padding: whiteLabel?.containerBox ? whiteLabel.containerBox.padding : '5px 15px 25px',
        },
      }
);

interface IContainerBox {
  title: string;
  subTitle?: string;
  actionTitle?: string;
  children: ReactNode;
  dashboard?: boolean;
}

export const ContainerBox: FC<IContainerBox> = ({ title, subTitle, children, dashboard }) => {
  const whiteLabel = useContext(WhiteLabelContext);

  return (
    <TitleContainerBox props={{ whiteLabel }}>
      <>
        <TitleBar props={{ whiteLabel }}>
          <TitleTypography>{title}</TitleTypography>
        </TitleBar>
        <SubTitleTypography>{subTitle}</SubTitleTypography>
        <Divider
          sx={{
            borderColor: '#EAEDF3',
          }}
        />
      </>
      <MainContent props={{ whiteLabel, dashboard }}>{children}</MainContent>
    </TitleContainerBox>
  );
};
