import { MuiAppBar } from './MuiAppBar';
import { MuiBackdrop } from './MuiBackdrop';
import { MuiButton } from './MuiButton';
import { MuiButtonBase } from './MuiButtonBase';
import { MuiDrawer } from './MuiDrawer';
import { MuiFormControl } from './MuiFormControl';
import { MuiFormControlLabel } from './MuiFormControlLabel';
import { MuiGrid } from './MuiGrid';
import { MuiInput } from './MuiInput';
import { MuiInputAdornment } from './MuiInputAdornment';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiLink } from './MuiLink';
import { MuiListItem } from './MuiListItem';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiNativeSelect } from './MuiNativeSelect';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiSelect } from './MuiSelect';
import { MuiSwitch } from './MuiSwitch';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiToolbar } from './MuiToolbar';
import { MuiTooltip } from './MuiTooltip';
import { MuiTypography } from './MuiTypography';
import { ThemeComponents } from './types';

export const components: ThemeComponents = {
  MuiListItem,
  MuiAppBar,
  MuiBackdrop,
  MuiButton,
  MuiButtonBase,
  MuiDrawer,
  MuiFormControl,
  MuiFormControlLabel,
  MuiGrid,
  MuiInput,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiMenuItem,
  MuiNativeSelect,
  MuiOutlinedInput,
  MuiSwitch,
  MuiToggleButton,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
  MuiSelect,
};
