import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

import { SUPPORTED_REGION } from '@driva-development/driva-types';

import cross from '../../../../assets/img/cross.svg';
import { redirectModalClasses, StyledRedirectModal } from '../../../../components/Redirects/styles';
import { REGION_COOKIE } from '../../../../shared/constants';

const PREFIX = 'RegionSelectModal';

const regionSelectModalClasses = {
  modalRoot: `${PREFIX}-modalRoot`,
  modalBody: `${PREFIX}-modalBody`,
  modalHeader: `${PREFIX}-modalHeader`,
  modalText: `${PREFIX}-modalText`,
  modalButton: `${PREFIX}-modalButton`,
  modalWrapper: `${PREFIX}-modalWrapper`,
  imgBox: `${PREFIX}-imgBox`,
  crossImg: `${PREFIX}-crossImg`,
  buttonText: `${PREFIX}-buttonText`,
  modalSubButton: `${PREFIX}-modalSubButton`,
};

const StyledRegionSelectModal = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 120,
  },
  [`& .${regionSelectModalClasses.modalRoot}`]: {
    background: '#fff',
    padding: '15px',
    borderRadius: '5px',
    color: '#3E3F42',
    margin: '0 auto',
    maxWidth: 549,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginBottom: 35,
    fontStyle: 'normal',
  },
  [`& .${regionSelectModalClasses.modalBody}`]: {
    padding: '0px 30px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px',
    },
    textAlign: 'center',
  },
  [`& .${regionSelectModalClasses.modalHeader}`]: {
    fontSize: '27px',
    lineHeight: '34px',
    paddingTop: 10,
    fontFamily: 'Ivyjournal,sans-serif',
  },
  [`& .${regionSelectModalClasses.modalText}`]: {
    fontWeight: 500,
    fontSize: 17,
    paddingTop: '30px',
    fontFamily: 'Avenir',
  },
  [`& .${regionSelectModalClasses.buttonText}`]: {
    fontFamily: 'Avenir',
    textDecoration: 'none',
    color: '#3E3F42',
  },
  [`& .${regionSelectModalClasses.modalButton}`]: {
    width: 250,
    marginTop: 45,
  },
  [`& .${regionSelectModalClasses.modalSubButton}`]: {
    background: '#fff',
    marginTop: 8,
    color: '#3E3F42',
    textDecoration: 'underline',
  },
  [`& .${regionSelectModalClasses.imgBox}`]: {
    alignSelf: 'end',
    paddingTop: 10,
    paddingRight: 10,
  },
  [`& .${regionSelectModalClasses.crossImg}`]: {
    height: 16,
    width: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface RegionSelectModalProps {
  closeModal: any;
  showModal: boolean;
  setCookie: any;
  region: string;
}

/**
 * if a customer visits .com.au domain from NZ then they are shown this modal to select the correct region
 * same logic is used for if a customer visits .co.nz domain from AU
 * @param closeModal
 * @param showModal
 *
 */
const RegionSelectModal: FC<RegionSelectModalProps> = ({ closeModal, showModal, setCookie, region }) => {
  const redirectLink = region === SUPPORTED_REGION.AU ? 'https://www.driva.co.nz' : 'https://www.driva.com.au';
  const suggestedRegionText = region === SUPPORTED_REGION.AU ? 'New Zealand' : 'Australia';
  const suggestedRegionSubText = region === SUPPORTED_REGION.AU ? 'New Zealand' : 'Australian';
  const currentRegion = region === SUPPORTED_REGION.AU ? 'Australia' : 'New Zealand';

  const handleClose = () => {
    setCookie(REGION_COOKIE, 'selected');
    closeModal();
  };

  return (
    <StyledRedirectModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      closeAfterTransition
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <div className={`${redirectModalClasses.modalWrapper} ${redirectModalClasses.modalWrapperBreakdown}`}>
        <StyledRegionSelectModal>
          <Fade in={showModal}>
            <div className={regionSelectModalClasses.modalRoot} data-cy="new-quote-modal">
              <div onClick={handleClose} className={regionSelectModalClasses.imgBox} data-cy="close-region-select-modal">
                <img className={regionSelectModalClasses.crossImg} src={cross} alt="" />
              </div>
              <div className={regionSelectModalClasses.modalBody}>
                <div className={regionSelectModalClasses.modalHeader}>We noticed you're currently in {suggestedRegionText}.</div>
                <div className={regionSelectModalClasses.modalText}>Want to visit our {suggestedRegionSubText} website instead?</div>
                <Button className={regionSelectModalClasses.modalButton} variant="contained" data-cy="new-quote-modal-button">
                  <a className={regionSelectModalClasses.buttonText} href={redirectLink}>
                    Yes - take me there
                  </a>
                </Button>
                <Button onClick={handleClose} className={regionSelectModalClasses.modalSubButton} variant="contained" data-cy="new-quote-modal-button">
                  No - I'm applying for a loan in {currentRegion}
                </Button>
              </div>
            </div>
          </Fade>
        </StyledRegionSelectModal>
      </div>
    </StyledRedirectModal>
  );
};

export default RegionSelectModal;
