import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/img/drivacolourlogo-new.svg';

import topBar from './basicTopBar.module.scss';

export const BasicTopBar: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={topBar.topBarContainer}>
      <a className={topBar.logoLink} href={t('homepage.url')}>
        <img src={logo} alt="driva logo" />
      </a>
    </div>
  );
};
