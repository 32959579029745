import React, { FC, useEffect, useState } from 'react';
import { classNames } from 'utils';

import { Alert } from '../assets';

import styles from './errorSummary.module.scss';

const ERROR_SUMMARY_DELAY = 300;

interface ErrorSummaryProps {
  errorMessage?: string;
}

export const ErrorSummary: FC<ErrorSummaryProps> = ({ errorMessage }) => {
  const [errorMessages, setErrorMessages] = useState<string | undefined>(undefined);
  const [showErrorsStyle, setShowErrorsStyle] = useState<string>('');

  useEffect(() => {
    setTimeout(() => setErrorMessages(errorMessage), errorMessage == null ? ERROR_SUMMARY_DELAY : 0);
  }, [errorMessage]);

  useEffect(() => {
    if (errorMessage != null) {
      setTimeout(() => setShowErrorsStyle(styles.showErrors), 10);
    } else {
      setShowErrorsStyle('');
    }
  }, [errorMessage]);

  if (errorMessages == null) {
    return null;
  }

  const [label, message] = errorMessages.split(' - ', 2);

  return (
    <div className={classNames(styles.errorSummaryContainer, showErrorsStyle)}>
      <div className={styles.messagesIcon}>
        <img src={Alert} alt="" />
      </div>
      <div className={styles.messagesContainer} data-cy="errorMessages">
        <p>
          {message ? (
            <>
              <b>{label}</b> - {message}
            </>
          ) : (
            label
          )}
        </p>
      </div>
    </div>
  );
};
