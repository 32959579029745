import React, { createContext, FC, ReactNode, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { BREAKPOINTS } from 'shared/constants';

interface ViewportProps {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  breakpoint: number;
}

const ViewportContext = createContext<ViewportProps>({
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  breakpoint: BREAKPOINTS.MOBILE,
});

ViewportContext.displayName = 'ViewportContext';

const breakpointArray = Object.values(BREAKPOINTS).reverse();

export const ViewportProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [breakpoint, setBreakpoint] = useState<number>(BREAKPOINTS.MOBILE);

  const handleResize = useCallback(() => {
    const { innerWidth } = window;
    const { TABLET, DESKTOP: DESKTOP_SMALL } = BREAKPOINTS;
    setIsMobile(innerWidth < TABLET);
    setIsTablet(innerWidth >= TABLET && innerWidth <= DESKTOP_SMALL);
    setIsDesktop(innerWidth >= DESKTOP_SMALL);
    setBreakpoint(breakpointArray.find((bp) => innerWidth >= bp) ?? 0);
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <ViewportContext.Provider
      value={{
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile,
        isTablet,
        isDesktop,
        breakpoint,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportContext);
